
import React, { useEffect, useState } from "react";
import { FaComments, FaListAlt, FaPlusCircle } from "react-icons/fa";
import AddChatCategory from "../components/chatCategory/AddChatCategory";
import ChatCategoryList from "../components/chatCategory/ChatCategoryList";
import { useApp, useAuth } from "../hooks";
import { getChatCategoryService } from "../services/category.service";
import { getDatabasesService } from "../services/databases.service";
import { checkIfUserIsAdmin, checkIfUserIsOwner } from "../services/auth.service";
import { useNavigate } from "react-router-dom";
import { Box, Tab, Tabs, Typography, useMediaQuery, useTheme, styled, Paper } from "@mui/material";

const ChatCategoryPage = () => {
  const { state, dispatch }: any = useApp();
  const { auth }: any = useAuth();
  const navigate = useNavigate();
  const [tabIndex, setTabIndex] = useState(0);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));



  useEffect(() => {
    if (state.selectedOrganisation?._id) {
      const { role } = state.selectedOrganisation.members.find((m: any) => m.user_id === auth.user.user_id);
      if ((role === 'owner' || role === 'admin') && (state.selectedOrganisation?.subscription_status !== 'active')) {
        navigate(`/organisation/${state.selectedOrganisation._id}/billing`);
        // show a notification
        dispatch({
          type: "SET_NOTIFICATION",
          payload: { show: true, title: 'Subscription', message: 'Your subscription is currently inactive. Please check your account settings.', type: 'error' }
        });
      }
      if (checkIfUserIsAdmin(state.selectedOrganisation, auth.user.user_id) || checkIfUserIsOwner(state.selectedOrganisation, auth.user.user_id)) {
        setInitialData();
      } else {
        dispatch({
          type: 'SET_NOTIFICATION',
          payload: { show: true, title: 'Authorization', message: 'You are not authorized!', type: 'warning' }
        });
        navigate(`/organisation/${state.selectedOrganisation._id}/category/1/thread/1`);
      }
    }
  }, [state.selectedOrganisation]);

  useEffect(() => {
    setInitialDataForDatabases();
  }, [state.selectedOrganisation]);

  const setInitialDataForDatabases = async () => {
    if (state.selectedOrganisation?._id) {
      const databases = await getDatabasesService(state.selectedOrganisation._id, null, null, null, null);
      if (databases.response.length > 0) {
        dispatch({ type: 'SET_DATABASES', payload: databases.response });
      }
    }
  };

  const setInitialData = async () => {
    if (state.selectedOrganisation?._id) {
      const categories = await getChatCategoryService(state.selectedOrganisation._id);
      if (categories.length > 0) {
        dispatch({ type: 'SET_CHAT_CATEGORIES', payload: categories });
      }
    }
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
  };
  const StyledTabs = styled(Tabs)(({ theme }) => ({
    '& .MuiTabs-indicator': {
      backgroundColor: theme.palette.primary.main,
      height: 3,
    },
  }));

  const StyledTab = styled(Tab)(({ theme }) => ({
    minWidth: 'unset',
    padding: theme.spacing(1.5, 2),
    fontSize: isMobile ? '0.875rem' : '1rem',
    '&.Mui-selected': {
      color: theme.palette.primary.main,
    },
  }));

  return (
      <Paper sx={{
        mx: 'auto',
        minHeight: '80vh',
        height: '100%',
        p: { xs: 1, sm: 3 },
        borderRadius: 2,
        boxShadow: theme.shadows[2],
        backgroundColor: 'background.paper',
        overflow: 'auto'
      }}>
        <Box sx={{ mb: 3 }}>
          {/*<Typography*/}
          {/*    variant="h4"*/}
          {/*    component="h1"*/}
          {/*    sx={{*/}
          {/*      fontWeight: 600,*/}
          {/*      mb: 2,*/}
          {/*      display: 'flex',*/}
          {/*      alignItems: 'center',*/}
          {/*      gap: 1.5,*/}
          {/*      color: 'text.primary'*/}
          {/*    }}*/}
          {/*>*/}
          {/*  <FaComments fontSize="1.2em" />*/}
          {/*  Chat Topics Management*/}
          {/*</Typography>*/}

          <StyledTabs
              value={tabIndex}
              onChange={handleTabChange}
              variant={isMobile ? 'scrollable' : 'standard'}
              scrollButtons="auto"
          >
            <StyledTab
                icon={<FaListAlt fontSize={isMobile ? '1rem' : '1.2rem'} />}
                label={isMobile ? null : 'Chat Topics'}
                iconPosition="start"
            />
            <StyledTab
                icon={<FaPlusCircle fontSize={isMobile ? '1rem' : '1.2rem'} />}
                label={isMobile ? null : 'New Topic'}
                iconPosition="start"
            />
          </StyledTabs>
        </Box>

        <Box sx={{
          pt: 2,
          minHeight: 400,
          transition: 'all 0.3s ease',
          height: '100%',
        }}>
          {tabIndex === 0 && (
              <ChatCategoryList isMobile={isMobile} />
          )}
          {tabIndex === 1 && (
              <AddChatCategory onSuccess={() => setTabIndex(0)} />
          )}
        </Box>
      </Paper>
  );
};

export default ChatCategoryPage;
