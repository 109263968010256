import React, { useEffect, useMemo, useState } from 'react';
import { useApp } from "../../hooks";
import { MdOutlineDelete, MdOutlineEdit, MdRefresh } from "react-icons/md";
import {
  createDatabaseFromFileService,
  deleteDatabaseService,
  editDatabaseNameService,
  getDatabasesService,
} from "../../services/databases.service";
import EditDatabaseDialog from "./EditDatabaseModal";
import Spinner from "../shared/Spinner";
import {
  Button,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Modal,
  Typography,
} from "@mui/material";

import { AllCommunityModule, ModuleRegistry, themeQuartz, colorSchemeDarkBlue, colorSchemeLightCold } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import {useTheme} from "@mui/material/styles";

ModuleRegistry.registerModules([AllCommunityModule]);


const themeLightCold = themeQuartz.withPart(colorSchemeLightCold);
const themeDarkBlue = themeQuartz.withPart(colorSchemeDarkBlue);

const DatabaseList: React.FC<any> = ({ setTabs }: any) => {
  const { state, dispatch }: any = useApp();
  const [loadingMap, setLoadingMap]: any = useState({});
  const [isDialogOpen, setIsDialogOpen]: any = useState(false);
  const [databaseToDelete, setDatabaseToDelete]: any = useState(null);
  const [editDatabaseData, setEditDatabaseData]: any = useState(null);
  const appTheme = useTheme();
  const [openModal, setOpenModal]: any = useState(false);
  const [modalTitle, setModalTitle]: any = useState("");
  const [modalContent, setModalContent]: any = useState(null);

    const [rowData, setRowData]: any = useState([]);



  const defaultColDef = useMemo(() => {
    return {

      flex: 1,
    };
  }, []);

  const setStatus = (message: any, type: any): void => {
    dispatch({
      type: "SET_NOTIFICATION",
      payload: {
        show: true,
        title: "Drive Service",
        message: message,
        type: type,
      },
    });
  };

  const [isFetching, setIsFetching]: any = useState(true);

  useEffect(() => {
    if(state?.databases?.length >= 0) {
      setIsFetching(false);
        setRowData(state.databases);
    }
  }, [state.databases]);

  const handleOpenDialog = (database: any): void => {
    setDatabaseToDelete(database);
    setIsDialogOpen(true);
  };

  const handleCloseDialog = (): void => {
    setIsDialogOpen(false);
    setDatabaseToDelete(null);
  };

  const handleDeleteDatabase = async (): Promise<void> => {
    if (databaseToDelete) {
      await handleDatabaseDeleteConfirm(databaseToDelete.database_id);
      handleCloseDialog();
    }
  };

  const handleDatabaseDeleteConfirm = async (database_id: any): Promise<void> => {
    const { success, message }: any = await deleteDatabaseService(
      database_id,
      state.selectedOrganisation._id
    );
    if (success === 1) {
      setStatus("Drive deleted successfully", "success");
      const databases: any = await getDatabasesService(
        state.selectedOrganisation._id,
        null,
        null,
        null,
        null
      );
      console.log("databases", databases);
      dispatch({ type: "SET_DATABASES", payload: databases.response });
    } else {
      setStatus(message, "error");
    }
  };

  const handleOpenEditDialog = (database: any): void => {
    setEditDatabaseData(database);
    setModalTitle("Edit Database");
    setModalContent(
      <EditDatabaseDialog
        initialUsedVersion={database.version_in_use}
        initialVersion={database.version}
        databaseId={database.database_id}
        initialName={database.database_name}
        versions={database.versions}
        onConfirm={handleDatabaseUpdateConfirm}
        onCancel={handleCloseModal}
      />
    );
    setOpenModal(true);
  };

  const handleCloseModal = (): void => {
    setOpenModal(false);
    setEditDatabaseData(null);
  };

  const handleDatabaseUpdateConfirm = async (
    database_id: any,
    database_name: any,
    database_version: any
  ): Promise<void> => {
    const isUpdated: any = await editDatabaseNameService(
      database_id,
      database_name,
      state.selectedOrganisation._id,
      database_version
    );
    if (isUpdated) {
      setStatus("Drive has been successfully updated.", "success");
      const databases: any = await getDatabasesService(
        state.selectedOrganisation._id,
        null,
        null,
        null,
        null
      );
      dispatch({ type: "SET_DATABASES", payload: databases.response });
    } else {
      setStatus("Drive update failed!", "error");
    }
    handleCloseModal();
  };

  const handleRefresh = async (database: any): Promise<void> => {
    setLoadingMap({ ...loadingMap, [database.database_id]: true });
    dispatch({
      type: "SET_NOTIFICATION",
      payload: {
        show: true,
        title: "Drive Service",
        message: "The drive is being synced. Please wait...",
        type: "progress",
        toastId: database.database_id,
      },
    });
    try {
      const payload: any = {
        database_name: database.database_name,
        organisation_id: state.selectedOrganisation._id,
        is_file_upload: true,
        database_id: database.database_id,
        version: database?.version ?? null,
        file_ids: database?.file_ids ?? [],
        is_google_drive_file: database?.is_google_drive_file ?? false,
      };
      const { status }: any = await createDatabaseFromFileService(payload);
      if (status) {
        const databases: any = await getDatabasesService(
          state.selectedOrganisation._id,
          null,
          null,
          null,
          null
        );
        dispatch({ type: "SET_DATABASES", payload: databases.response });
        dispatch({
          type: "SET_NOTIFICATION",
          payload: {
            show: true,
            title: "Drive Service",
            message: "Drive has been successfully synchronised",
            type: "update",
            toastId: database.database_id,
            updateType: "success",
          },
        });
      } else {
        dispatch({
          type: "SET_NOTIFICATION",
          payload: {
            show: true,
            title: "Drive Service",
            message: "Unable to synchronise drive",
            type: "update",
            toastId: database.database_id,
            updateType: "error",
          },
        });
      }
    } catch (error: any) {
      dispatch({
        type: "SET_NOTIFICATION",
        payload: {
          show: true,
          title: "Drive Service",
          message: "Unable to synchronise drive",
          type: "update",
          toastId: database.database_id,
          updateType: "error",
        },
      });
    } finally {
      setLoadingMap({ ...loadingMap, [database.database_id]: false });
    }
  };


  const actionsCellRenderer = (props: any) => {

  return (
    <div
      style={{
        paddingTop: "10px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        gap: "8px",
      }}
    >
      {props.data?.is_google_drive_file && (
        <>
          {loadingMap[props.data.database_id] ? (
            <Spinner />
          ) : (
            <Button onClick={() => props.onRefresh(props.data)} >
              <MdRefresh size={16} />
            </Button>
          )}
        </>
      )}
      <Button onClick={() => props.onEdit(props.data)}>
        <MdOutlineEdit size={16} />
      </Button>
      <Button onClick={() => props.onDelete(props.data)}>
        <MdOutlineDelete size={16} />
      </Button>
    </div>
  );
}

// Column Definitions: Defines & controls grid columns.
  const [colDefs, setColDefs]: any = useState([
    { field: "database_name", headerName: "Drive Name", flex: 2, filter: "agTextColumnFilter",
      floatingFilter: true },
    { field: "version", headerName: "Version", type: 'numericColumn', filter: "agNumberColumnFilter",
      floatingFilter: true, flex: 0.5 },
    {
      field: "created_at",
      headerName: "Created At",
      filter: "agDateColumnFilter",
      floatingFilter: true,
      sort: 'desc',
      sortOrder: 0,

      // Convert the raw string into a JavaScript Date object
      valueGetter: (params: any) => {
        const rawDate = params.data.created_at;
        return rawDate ? new Date(rawDate) : null;
      },

      // Display the date/time in local format (locale-aware)
      valueFormatter: (params: any) => {
        const date = params.value; // already a Date object from valueGetter
        if (!date) return "";
        // This uses the user’s local time and formatting by default
        return date.toLocaleString();
      },

      // For date filtering logic (AG Grid will pass 'filterLocalDateAtMidnight' and 'cellValue')
      filterParams: {
        comparator: (filterLocalDateAtMidnight: any, cellValue: any) => {
          if (!cellValue) return -1;
          const cellDate = new Date(cellValue);

          // Clear time fields so comparison is just by date
          const cellDateNoTime = new Date(cellDate.getFullYear(), cellDate.getMonth(), cellDate.getDate());
          const filterDateNoTime = new Date(
              filterLocalDateAtMidnight.getFullYear(),
              filterLocalDateAtMidnight.getMonth(),
              filterLocalDateAtMidnight.getDate()
          );

          if (cellDateNoTime < filterDateNoTime) return -1;
          if (cellDateNoTime > filterDateNoTime) return 1;
          return 0;
        },
      },
    },
    { field: "updated_at", headerName: "Updated At", filter: "agDateColumnFilter",
      floatingFilter: true,
      valueGetter: (params: any) => {
        const rawDate = params.data.updated_at;
        return rawDate ? new Date(rawDate) : null;
      },
        valueFormatter: (params: any) => {
            const date = params.value;
            if (!date) return "";
            return date.toLocaleString();
        },
        filterParams: {
            comparator: (filterLocalDateAtMidnight: any, cellValue: any) => {
                if (!cellValue) return -1;
                const cellDate = new Date(cellValue);
                const cellDateNoTime = new Date(cellDate.getFullYear(), cellDate.getMonth(), cellDate.getDate());
                const filterDateNoTime = new Date(
                    filterLocalDateAtMidnight.getFullYear(),
                    filterLocalDateAtMidnight.getMonth(),
                    filterLocalDateAtMidnight.getDate()
                );
                if (cellDateNoTime < filterDateNoTime) return -1;
                if (cellDateNoTime > filterDateNoTime) return 1;
                return 0;
            },
        },
    },
    {
      headerName: "Actions",
      sortable: false,
      cellRenderer: actionsCellRenderer,
      cellRendererParams: {
        onEdit: handleOpenEditDialog,
        onDelete: handleOpenDialog,
        onRefresh: handleRefresh,
        loadingMap: loadingMap,
      },
        flex: 1.5,
    },
  ]);




  return (
      <div
          style={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            width: "100%",
          }}
      >
        <div style={{width: "100%", height: "80%", marginTop: "10px"}}>
          { isFetching ? (
              <div style={{display: "flex", justifyContent: "center", alignItems: "center", height: "100%"}}>
            <Spinner />
            </div>
            ) : (
          <AgGridReact
              rowData={rowData}
              columnDefs={colDefs}
              pagination={true}
              defaultColDef={defaultColDef}
              paginationPageSize={20}
              paginationPageSizeSelector={[10, 20, 50, 100]}
              theme={appTheme.palette.mode === "dark" ? themeDarkBlue : themeLightCold}
          />
            )
          }
        </div>

        <Dialog
            open={isDialogOpen}
            onClose={handleCloseDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Confirm Delete"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to delete this drive? This action cannot be
              undone.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog} color="primary">
              Cancel
            </Button>
            <Button onClick={handleDeleteDatabase} color="secondary" autoFocus>
              Delete
            </Button>
          </DialogActions>
        </Dialog>

        <Modal
            open={openModal}
            onClose={handleCloseModal}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
        >
          <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                bgcolor: "background.paper",
                boxShadow: 24,
                p: 4,
                width: "70%",
              }}
          >
            <Typography id="modal-title" variant="h6" component="h2">
              {modalTitle}
            </Typography>
            <Box id="modal-description" sx={{mt: 2}}>
              {modalContent}
            </Box>
          </Box>
        </Modal>
      </div>
  );
};

export default DatabaseList;
