import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import remarkDirective from 'remark-directive';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { atomDark, vs } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { AiFillCopy, AiOutlineCheck } from 'react-icons/ai';
import React, { useState } from 'react';
import './MarkdownStyles.css';
import { useTheme } from "@mui/material/styles";

interface IMarkdown {
    markdown: string;
}

// Add this to your Markdown component
const YouTubeEmbed = ({ url }: { url: string }) => {
    const getVideoId = (link: string) => {
        const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
        const match = link.match(regExp);
        return match && match[2].length === 11 ? match[2] : null;
    };

    const videoId = getVideoId(url);

    if (!videoId) return <a href={url}>{url}</a>;

    return (
        <div className="video-container">
            <iframe
                src={`https://www.youtube.com/embed/${videoId}?modestbranding=1&rel=0`}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title="YouTube video player"
                className="youtube-iframe"
            />
        </div>
    );
};

// Update your LinkRenderer component
const LinkRenderer = (props: any) => {
    const isYoutubeLink = props.href.includes('youtube.com') || props.href.includes('youtu.be');

    return isYoutubeLink ? (
        <YouTubeEmbed url={props.href} />
    ) : (
        <a
            href={props.href}
            target="_blank"
            rel="noreferrer"
            className="markdown-link"
        >
            {props.children}
        </a>
    );
};

const ImageComponent = ({ src, alt, title, onSelect }: { src?: string; alt?: string; title?: string; onSelect: () => void }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [hasError, setHasError] = useState(false);
    const [isHovered, setIsHovered] = useState(false);

    return (
        <figure
            className="image-container"
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            onClick={onSelect}
        >
            <div className="image-wrapper">
                {isLoading && !hasError && <div className="image-skeleton"/>}
                {src && (
                    <img
                        src={src}
                        alt={alt}
                        loading="lazy"
                        onLoad={() => setIsLoading(false)}
                        onError={() => {
                            setIsLoading(false);
                            setHasError(true);
                        }}
                        className={`image-content ${isLoading ? 'loading' : ''}`}
                    />
                )}
                {hasError && (
                    <div className="image-error">
                        Failed to load image
                    </div>
                )}
                {title && (
                    <figcaption className={`image-caption ${isHovered ? 'visible' : ''}`}>
                        {title}
                    </figcaption>
                )}
            </div>
        </figure>
    );
};

const Lightbox = ({src, alt, title, onClose}: { src?: string; alt?: string; title?: string; onClose: () => void }) => {
    return (
        <div className="lightbox-overlay" onClick={onClose}>
            <div className="lightbox-content" onClick={(e) => e.stopPropagation()}>
                <button className="lightbox-close" onClick={onClose}>×</button>
                {src && <img src={src} alt={alt} className="lightbox-image" />}
                {title && <div className="lightbox-caption">{title}</div>}
            </div>
        </div>
    );
};

const Markdown = ({ markdown }: IMarkdown) => {
    const theme = useTheme().palette.mode;
    const [copied, setCopied] = useState<string | null>(null);
    const [selectedImage, setSelectedImage] = useState<{ src: string; alt?: string; title?: string } | null>(null);

    const handleCopy = (content: string) => {
        setCopied(content);
        setTimeout(() => setCopied(null), 2000);
    };

    return (
        <div className={`markdown-container ${theme === 'dark' ? 'dark-theme' : 'light-theme'}`}>
            <ReactMarkdown
                remarkPlugins={[remarkGfm, remarkDirective]}
                className={`markdown ${theme === 'dark' ? 'dark-theme' : ''}`}
                components={{
                    a: LinkRenderer,
                    code({ node, className, children }) {
                        const match = /language-(\w+)/.exec(className || '');
                        const content = String(children).replace(/\n$/, '');

                        return !match ? (
                            <code className={className}>{children}</code>
                        ) : (
                            <div className="code-block">
                                <div className="code-header">
                                    <span className="language-tag">{match[1]}</span>
                                    <CopyToClipboard text={content} onCopy={() => handleCopy(content)}>
                                        <button className="copy-button">
                                            {copied === content ? (
                                                <AiOutlineCheck className="copy-icon" />
                                            ) : (
                                                <AiFillCopy className="copy-icon" />
                                            )}
                                        </button>
                                    </CopyToClipboard>
                                </div>
                                <SyntaxHighlighter
                                    style={theme === 'dark' ? atomDark : vs}
                                    language={match[1]}
                                    PreTag="div"
                                    children={content}
                                />
                            </div>
                        );
                    },
                    img: ({ node, ...props }) => (
                        <ImageComponent
                            {...props}
                            onSelect={() => setSelectedImage({ src: props.src || '', alt: props.alt, title: props.title })}
                        />
                    ),
                    blockquote: ({ children }) => (
                        <blockquote className="styled-blockquote">
                            <div className="quote-decoration">“</div>
                            {children}
                        </blockquote>
                    ),
                    table: ({ children }) => (
                        <div className="table-container">
                            <table>{children}</table>
                        </div>
                    ),
                }}
            >
                {markdown}
            </ReactMarkdown>

            {selectedImage && (
                <Lightbox
                    src={selectedImage.src}
                    alt={selectedImage.alt}
                    title={selectedImage.title}
                    onClose={() => setSelectedImage(null)}
                />
            )}
        </div>
    );
};

export default Markdown;