import Markdown from "../shared/Markdown";
import { FaCheck, FaCopy, FaPlay, FaStop, FaRobot, FaUser } from 'react-icons/fa';
import { motion, AnimatePresence } from 'framer-motion';
import React, { useState, useRef, useEffect, useCallback } from "react";
import './ChatLine.css';
import { textToSpeech } from "../../services/chat.service";
import Spinner from "../shared/Spinner";
import { useApp } from "../../hooks";
import { styled, useTheme } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Skeleton from "@mui/material/Skeleton";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import {alpha, Avatar} from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import {Vortex} from "react-loader-spinner";

export type Message = {
  who: 'bot' | 'user' | undefined;
  message?: string;
  spents: any;
  messageId: string;
  threadId: string;
  username: string | undefined;
  userId: string | undefined;
  createdAt: string;
    isCompleteMessage?: boolean;
    loading?: boolean;
    isLastMessage?: boolean;
};

const generateRandomWidths = () => {
  const widths = [];
  let total = 0;

  while (total < 100) {
    const remaining = 100 - total;
    const width = Math.min(remaining, Math.random() * 30 + 10);
    widths.push(width);
    total += width;
  }

  return widths;
};

export const LoadingChatLine = () => {
    return (
        <div className="mini-loader">
            <Vortex
                visible={true}
                height="30"
                width="30"
                ariaLabel="vortex-loading"
                colors={['#6366f1', '#4f46e5', '#4338ca', '#3730a3', '#312e81', '#1e1b4b']}
            />
        </div>
    );
};

const ChatLineContainer = styled(Paper, {
    shouldForwardProp: (prop) => prop !== 'who',
})<{ who: 'bot' | 'user' | undefined }>(({ theme, who }) => ({
    position: 'relative',
    maxWidth: '90%',
    width: 'fit-content',
    minWidth: '300px',
    margin: theme.spacing(1),
    padding: theme.spacing(2.5),
    borderRadius: '12px',
    transition: 'all 0.2s ease',
    boxSizing: 'border-box',
    border: '1px solid transparent',
    background: 'none',
    boxShadow: 'none',

    // AI Message Styling (Minimalist Professional)
    ...(who === 'bot' && {
        borderLeft: `2px solid ${theme.palette.mode === 'dark'
            ? theme.palette.primary.dark
            : theme.palette.primary.light}`,
        backgroundColor: 'transparent',
        boxShadow: theme.shadows[0],
        '&:hover': {
            backgroundColor: theme.palette.mode === 'dark'
                ? 'rgba(255, 255, 255, 0.02)'
                : 'rgba(0, 0, 0, 0.01)',
            transform: 'translateX(2px)'
        }
    }),

    // User Message Styling (Subtle Emphasis)
    ...(who === 'user' && {
        backgroundColor: theme.palette.mode === 'dark'
            ? 'rgba(255, 255, 255, 0.05)'
            : 'rgba(0, 0, 0, 0.03)',
        border: `1px solid ${theme.palette.mode === 'dark'
            ? 'rgba(255, 255, 255, 0.1)'
            : 'rgba(0, 0, 0, 0.08)'}`,
        borderRight: `2px solid ${theme.palette.secondary.main}`,
        boxShadow: theme.shadows[1],
        '&:hover': {
            backgroundColor: theme.palette.mode === 'dark'
                ? 'rgba(255, 255, 255, 0.07)'
                : 'rgba(0, 0, 0, 0.05)',
            transform: 'translateX(-2px)'
        }
    }),

    [theme.breakpoints.down('sm')]: {
        maxWidth: '100%',
        minWidth: 'unset',
        padding: theme.spacing(2)
    }
}));

const ActionButton = styled(IconButton)(({ theme }) => ({
    padding: '6px',
    margin: '0 4px',
    backgroundColor: theme.palette.mode === 'dark' ?
        'rgba(255, 255, 255, 0.1)' :
        'rgba(0, 0, 0, 0.05)',
    '&:hover': {
        backgroundColor: theme.palette.mode === 'dark' ?
            'rgba(255, 255, 255, 0.2)' :
            'rgba(0, 0, 0, 0.1)'
    }
}));

export function ChatLine({ who = 'bot', message, spents, threadId, messageId, userId, username, createdAt, loading, isLastMessage }: Message) {
    const theme = useTheme();
    const [isCopied, setIsCopied] = useState(false);
    const [isPlaying, setIsPlaying] = useState(false);
    const audioRef = useRef<HTMLAudioElement | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const { state, dispatch }: any = useApp();

  const copyToClipboard = async () => {
    if (message) {
      try {
        await navigator.clipboard.writeText(message);
        setIsCopied(true);
        setTimeout(() => setIsCopied(false), 3000);
      } catch (err) {
        console.log('Something went wrong', err);
      }
    }
  };

const playAudio = async () => {
        if (!message) {
            return;
        }
        setIsLoading(true);
        const audioElement: any = audioRef.current;
        if (!audioElement) {
            console.error('No audio element found');
            return;
        }

        const response: any = await textToSpeech(message, messageId, state.selectedOrganisation._id);
        if (response.data && response.data.data.audio_url) {
            audioElement.src = response.data.data.audio_url;
            audioElement.play();
            setIsLoading(false);
            setIsPlaying(true);

            audioElement.onended = () => {
                setIsPlaying(false);
            };
        } else {
            setIsLoading(false);
            console.log('Failed to get audio URL from response');
        }
    };


  const stopAudio = useCallback(() => {
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
      setIsPlaying(false);
    }
  }, [audioRef, setIsPlaying]);

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      const chatLineId = `${who === 'bot' ? `bot-${messageId}` : `user-${messageId}`}`;
      const chatLineElement = document.getElementById(chatLineId);

      if (chatLineElement) {
        const chatLineTop = chatLineElement.offsetTop;
        const chatLineBottom = chatLineTop + chatLineElement.offsetHeight;

        if (scrollY > chatLineBottom || scrollY < chatLineTop) {
          stopAudio();
        }
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, [who, messageId, stopAudio]);

  return (
      <AnimatePresence>
          {message && (
              <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -20 }}
                  transition={{ duration: 0.3 }}
                  className={`clear-both flex py-2 justify-center`}
              >
                  <ChatLineContainer
                      elevation={1}
                      who={who}
                      sx={{
                          // ml: who === 'bot' ? 1 : 4,
                          // mr: who === 'bot' ? 4 : 1,
                          width: "100%",
                          border: `1px solid ${theme.palette.divider}`
                      }}
                  >
                      <Box display="flex" alignItems="center" mb={2}>
                          <Avatar sx={{
                              width: 32,
                              height: 32,
                              mr: 2,
                              bgcolor: who === 'bot' ? theme.palette.primary.main : theme.palette.secondary.main
                          }}>
                              {who === 'bot' ? (
                                  <FaRobot style={{ fontSize: '1rem' }} />
                              ) : (
                                  <FaUser style={{ fontSize: '1rem' }} />
                              )}
                          </Avatar>
                          <Typography
                              variant="subtitle2"
                              sx={{
                                  fontWeight: 600,
                                  color: theme.palette.text.primary
                              }}
                          >
                              {who === 'bot' ? 'DatalinkerAI' : username}
                          </Typography>
                          {/*<Typography variant="caption" sx={{ ml: 1, color: theme.palette.text.secondary }}>*/}
                          {/*   {isNaN(new Date(createdAt).getTime()) ? new Date().toLocaleTimeString() : new Date(createdAt).toLocaleTimeString()}*/}
                          {/*</Typography>*/}
                      </Box>



                      <Box position="relative">
                          <Markdown markdown={message} />

                          {who === 'bot' && loading && isLastMessage && (
                               <LoadingChatLine />
                            )
                          }

                          {who === 'bot' && (
                              <Box
                                  sx={{
                                      position: 'absolute',
                                      top: '-10px',
                                      right: '-10px',
                                      display: 'flex',
                                      bgcolor: 'background.paper',
                                      borderRadius: '20px',
                                      boxShadow: 1,
                                      padding: '4px'
                                  }}
                              >
                                  <Tooltip title={isCopied ? "Copied!" : "Copy to clipboard"} arrow>
                                      <ActionButton onClick={copyToClipboard} size="small">
                                          {isCopied ? (
                                              <FaCheck style={{ fontSize: '0.9rem' }} />
                                          ) : (
                                              <FaCopy style={{ fontSize: '0.9rem' }} />
                                          )}
                                      </ActionButton>
                                  </Tooltip>

                                  <Tooltip title={isPlaying ? "Stop audio" : "Play audio"} arrow>
                                      <ActionButton
                                          onClick={isPlaying ? stopAudio : playAudio}
                                          size="small"
                                          disabled={isLoading}
                                      >
                                          {isLoading ? (
                                              <Spinner />
                                          ) : isPlaying ? (
                                              <FaStop style={{ fontSize: '0.9rem' }} />
                                          ) : (
                                              <FaPlay style={{ fontSize: '0.9rem' }} />
                                          )}
                                      </ActionButton>
                                  </Tooltip>
                              </Box>
                          )}
                      </Box>

                  </ChatLineContainer>
                  <audio ref={audioRef} />
              </motion.div>

          )}
      </AnimatePresence>
  );
}

