import * as React from 'react';
import { Routes, Route } from "react-router-dom";
import AuthLayout from "./layouts/AuthLayout";
import Layout from "./layouts/Layout";
import HomePage from "./pages/HomePage";
import ChatCategoryPage from "./pages/ChatCategoryPage";
import RegisterPage from "./pages/RegisterPage";
import LoginPage from "./pages/LoginPage";
import PasswordResetPage from "./pages/PasswordRestePage";
import PaymentStatusPage from "./pages/PaymentStatusPage";
import AuthenticationStatusPage from "./pages/AuthenticationStatusPage";
import DatabasesPage from "./pages/DatabasesPage";
import LandingPage from "./pages/LandingPage";
import FilesPage from "./pages/FilesPage";
import VerifyEmailPage from "./pages/VerifyEmailPage";
import ToolsPage from "./pages/ToolsPage";
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import { useTheme, ThemeProvider, createTheme, Theme } from '@mui/material/styles';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import OrganisationSettings from './components/organisation/OrganisationSettings';
import { useParams } from "react-router-dom";
import OrganisationUsers from './components/organisation/OrganisationUsers';
import BillingComponent from './components/organisation/BillingComponent';
declare module '@mui/material/styles' {
  interface Palette {
    gradients: {
      primary: string;
      primaryHover: string;
      primaryActive: string;
    };
  }
  interface PaletteOptions {
    gradients?: {
      primary?: string;
      primaryHover?: string;
      primaryActive?: string;
    };
  }
}

const ColorModeContext = React.createContext({ toggleColorMode: () => {} });

export function DarkModeToggle() {
  const theme = useTheme();
  const colorMode = React.useContext(ColorModeContext);
  return (
    <Box>
      <IconButton sx={{ ml: 1 }} onClick={colorMode.toggleColorMode} color="inherit">
        {theme.palette.mode === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />}
      </IconButton>
    </Box>
  );
}

function App() {
  const [mode, setMode] = React.useState<'light' | 'dark'>(() => {
    const savedMode = localStorage.getItem('themeMode');
    return savedMode ? (savedMode as 'light' | 'dark') : 'light';
  });
  const { orgId } = useParams();

  const colorMode = React.useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => {
          const newMode = prevMode === 'light' ? 'dark' : 'light';
          localStorage.setItem('themeMode', newMode);
          return newMode;
        });
      },
    }),
    [],
  );

  const theme = React.useMemo(
      () =>
          createTheme({
            palette: {
              mode,
              primary: {
                main: '#7C3AED', // Your purple
                light: mode === 'light' ? '#A78BFA' : '#9333EA',
                dark: mode === 'light' ? '#5B21B6' : '#4C1D95',
                contrastText: '#FFFFFF',
              },
              secondary: {
                main: '#3B82F6', // Your blue
                light: mode === 'light' ? '#60A5FA' : '#2563EB',
                dark: mode === 'light' ? '#1D4ED8' : '#1E40AF',
                contrastText: '#FFFFFF',
              },
              background: {
                default: mode === 'light' ? '#F3F4F6' : '#111827',
                paper: mode === 'light' ? '#FFFFFF' : '#1F2937',
              },
              text: {
                primary: mode === 'light' ? '#111827' : '#F3F4F6',
                secondary: mode === 'light' ? '#4B5563' : '#9CA3AF',
                disabled: mode === 'light' ? '#D1D5DB' : '#4B5563',
              },
              divider: mode === 'light' ? '#E5E7EB' : '#374151',
              gradients: {
                primary: 'linear-gradient(45deg, #7C3AED 30%, #3B82F6 90%)',
                primaryHover: 'linear-gradient(45deg, #9333EA 30%, #2563EB 90%)',
                primaryActive: 'linear-gradient(45deg, #5B21B6 30%, #1E40AF 90%)',
              },
            },
            typography: {
              fontFamily: [
                'Inter',
                '-apple-system',
                'BlinkMacSystemFont',
                'Segoe UI',
                'Roboto',
                'sans-serif',
              ].join(','),
              h1: {
                fontWeight: 700,
                fontSize: '2.25rem',
                color: mode === 'light' ? '#1F2937' : '#F9FAFB',
              },
              body1: {
                lineHeight: 1.6,
              },
            },
            components: {
              MuiButton: {
                styleOverrides: {
                  root: {
                    borderRadius: '8px',
                    textTransform: 'none',
                    background: 'linear-gradient(45deg, #7C3AED 30%, #3B82F6 90%)',
                    color: '#FFFFFF',
                    '&:hover': {
                      background: 'linear-gradient(45deg, #A78BFA 30%, #60A5FA 90%)',
                    },
                    '&:active': {
                      background: 'linear-gradient(45deg, #4C1D95 30%, #1E40AF 90%)',
                    },
                  },
                },
              },
              MuiAppBar: {
                styleOverrides: {
                  root: {
                    backgroundColor: mode === 'light' ? '#FFFFFF' : '#1F2937',
                    borderBottom: `1px solid ${mode === 'light' ? '#E5E7EB' : '#374151'}`,
                    boxShadow: 'none',
                  },
                },
              },
              MuiDrawer: {
                styleOverrides: {
                  paper: {
                    backgroundColor: mode === 'light' ? '#FFFFFF' : '#1F2937',
                    borderRight: `1px solid ${mode === 'light' ? '#E5E7EB' : '#374151'}`,
                  },
                },
              },
              MuiTextField: {
                styleOverrides: {
                  root: {
                    '& .MuiOutlinedInput-root': {
                      borderRadius: '8px',
                      '& fieldset': {
                        borderColor: mode === 'light' ? '#E5E7EB' : '#374151',
                      },
                      '&:hover fieldset': {
                        borderColor: mode === 'light' ? '#D1D5DB' : '#4B5563',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: mode === 'light' ? '#7C3AED' : '#3B82F6',
                      },
                    },
                  },
                },
              },
            },
            shape: {
              borderRadius: 8,
            },
            shadows: [
              'none', // elevation 0
              mode === 'light'
                  ? '0 1px 3px rgba(0, 0, 0, 0.05)'  // elevation 1 - light
                  : '0 1px 3px rgba(255, 255, 255, 0.05)', // elevation 1 - dark
              // Elevations 2-24
              ...Array(23).fill(
                  mode === 'light'
                      ? '0 1px 3px rgba(0, 0, 0, 0.1)'  // light mode shadows
                      : '0 1px 3px rgba(255, 255, 255, 0.1)'  // dark mode shadows
              )
            ] as Theme['shadows'],
          }),
      [mode]
  );

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <Routes>
          <Route path="/auth/" element={<AuthLayout />}>
            <Route path="login" element={<LoginPage />} />
            <Route path="register" element={<RegisterPage />} />
            <Route path="reset-password" element={<PasswordResetPage />} />
          </Route>
          <Route path="/" element={<Layout />}>
            <Route path="/organisation/:orgId/category/:categoryIdParam/thread/:threadIdParam" element={<HomePage />} />
            <Route path="verify-email" element={<VerifyEmailPage />} />
          </Route>
          <Route path="/organisation/:orgId" element={<Layout />}>
            <Route path="settings" element={<OrganisationSettings />} />
            <Route path="users" element={<OrganisationUsers />} />
            <Route path="billing" element={<BillingComponent />} />
            <Route path="chat-category" element={<ChatCategoryPage />} />
            <Route path="databases" element={<DatabasesPage />} />
            <Route path="files" element={<FilesPage />} />
            <Route path="tools" element={<ToolsPage />} />
          </Route>
          <Route path="payment/:status" element={<PaymentStatusPage />} />
          <Route path="authentication/:status" element={<AuthenticationStatusPage />} />
          <Route path="home" element={<LandingPage />} />
        </Routes>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
