import React, {useEffect, useMemo, useState} from 'react';
import { useApp } from "../../hooks";
import { MdOutlineDelete, MdOutlineEdit } from "react-icons/md";
import { CgUserList } from "react-icons/cg";
import { HiUserAdd } from "react-icons/hi";
import ChatCategoryAssign from "./ChatCategoryAssign";
import ChatCategoryUsers from "./ChatCategoryUsers";
import {
  deleteChatCategoryService,
  getAllChatCategoryService,
} from "../../services/category.service";
import {
  Button,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Chip,
  Modal,
  Typography,
  Tooltip,
} from '@mui/material';
import ChatContentForm from "./ChatContentForm";
import Spinner from "../shared/Spinner";

import { AllCommunityModule, ModuleRegistry, themeQuartz, colorSchemeDarkBlue, colorSchemeLightCold } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import {useTheme} from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

ModuleRegistry.registerModules([AllCommunityModule]);


const themeLightCold = themeQuartz.withPart(colorSchemeLightCold);
const themeDarkBlue = themeQuartz.withPart(colorSchemeDarkBlue);

const ChatCategoryList: React.FC<any> = () => {
  const { state, dispatch }: any = useApp();
  const { userChatCategories }: any = state;


  const [isDialogOpen, setIsDialogOpen]: any = useState(false);
  const [categoryToDelete, setCategoryToDelete]: any = useState(null);


  const [openModal, setOpenModal]: any = useState(false);
  const [modalTitle, setModalTitle]: any = useState('');
  const [modalContent, setModalContent]: any = useState(null);


  const [rowData, setRowData]: any = useState([]);
    const [isFetching, setIsFetching]: any = useState(true);
    const appTheme = useTheme();
  const defaultColDef = useMemo(() => {
    return {

      flex: 1,
    };
  }, []);
  const [colDefs, setColDefs]: any = useState([]);


  useEffect(() => {
    if (userChatCategories) {
      const data: any = userChatCategories.map((category: any) => {
        return {
          category_id: category.category_id,
          category_name: category.category_name,
          category_instruction: category.category_instruction,
          versions: category.versions,
          databaseIds: category.databaseIds,
            users: category.users
        };
      });
      setColumnDefs();
      setRowData(data);
      setIsFetching(false);

    }
  }, [userChatCategories]);

  const deleteChatCategory = (category: any): void => {
    setCategoryToDelete(category);
    setIsDialogOpen(true);
  };

  const handleConfirm = async (): Promise<void> => {
    if (!categoryToDelete) return;
    const isDeleted: any = await deleteChatCategoryService(
      state.selectedOrganisation._id,
      categoryToDelete.category_id
    );
    if (isDeleted) {
      setStatus('Deleted Successfully', 'success');
      const categories: any = await getAllChatCategoryService(
        state.selectedOrganisation._id
      );
      dispatch({
        type: 'SET_USER_CHAT_CATEGORIES',
        payload: categories || [],
      });
      if (categories.length > 0) {
        dispatch({
          type: 'SET_USER_ACTIVE_CHAT_CATEGORY',
          payload: categories[0] || {},
        });
      }
    } else {
      setStatus('Deletion failed!', 'error');
    }
    setIsDialogOpen(false);
  };

  const handleCancel = (): void => {
    setIsDialogOpen(false);
  };

  const setStatus = (message: any, type: any): void => {
    dispatch({
      type: 'SET_NOTIFICATION',
      payload: { show: true, title: 'Chat Topic delete', message, type },
    });
  };

  useEffect(() => {
    if (state.databases.length > 0) {
      const data: any = userChatCategories.map((category: any) => {
        return {
          category_id: category.category_id,
          category_name: category.category_name,
          category_instruction: category.category_instruction,
          versions: category.versions,
          databaseIds: category.databaseIds,
          users: category.users
        };
      });
      setColumnDefs();
      setRowData(data);
      setIsFetching(false
        );
    }

  }, [state.databases]);


  const setColumnDefs = () => {
    setColDefs([
      { field: "category_name", headerName: "Chat Topic Name", flex: 2, filter: "agTextColumnFilter",
        floatingFilter: true },

      { field: "drives", headerName: "Drives", cellRenderer: driveCellRenderer, flex: 2,
        filter: "agTextColumnFilter", autoHeight: true, floatingFilter: true,
        sortable: false,
        valueGetter: driveColumnValueGetter,
      },
      {
        field: "versions",
        headerName: "Version",
        flex: 1,
        sortable: false,
        filter: "agTextColumnFilter",
        floatingFilter: true,
        valueGetter: (params: any) => {
          return params.data?.versions?.length ?? 'N/A';
        }
      },
      {
        headerName: "Actions",
        cellRenderer: actionsCellRenderer,
        sortable: false,
        flex: 2,
      },

    ]);
  }

 const driveCellRenderer = (params: any) => {
    let databases: any = state.databases || [];
    const databasesOfCategory: any = params.data.databaseIds || [];
    databases = databases.filter((db: any) =>
        databasesOfCategory.includes(db.database_id)
    );
    return (
        <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              gap: '4px',
              margin: '4px',
              maxWidth: '200px',
              overflow: 'hidden',
            }}
        >
          {databases.map((db: any) => (
              <Tooltip title={db.database_name} key={db.database_id}>
                <Chip
                    label={db.database_name}
                    sx={{
                      maxWidth: '150px',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',

                    }}
                />
                </Tooltip>
            ))}

        </Box>
    );


 }

  const driveColumnValueGetter = (params: any) => {
    const rowData = params.data;

    if (!rowData || !rowData.databaseIds) {
      return [];
    }
    const databaseNames = rowData.databaseIds.map((dbId: number) => {
      const db = state.databases.find((d: any) => d.database_id === dbId);
      return db ? db.database_name : null;
    });
    return databaseNames.filter(Boolean);
  };

 const actionsCellRenderer = (params: any) => {
   console.log(params);
    return (
        <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              gap: '8px',
              margin: '4px',

            }}
        >
          <Button
              onClick={() =>
                  openModalWithContent(
                      'Update Chat Topic',
                      <ChatContentForm
                          initialCategoryId={params.data.category_id}
                          initialCategoryInstruction={params.data.category_instruction}
                          initialDatabaseIds={params.data.databaseIds}
                          initialCategoryName={params.data.category_name}
                      />
                  )
              }
              color="primary"
          >
            <MdOutlineEdit size={20} />
          </Button>
          <Button onClick={() => deleteChatCategory(params.data)} color="secondary">
            <MdOutlineDelete size={20} />
          </Button>
          <Button
              onClick={() =>
                  openModalWithContent(
                      'Chat Topic Users',
                      <ChatCategoryUsers category={params.data} />
                  )
              }
          >
            <CgUserList size={20} />
          </Button>
          <Button
              onClick={() =>
                  openModalWithContent(
                      'Assign Chat Topic',
                      <ChatCategoryAssign category={params.data} />
                  )
              }
          >
            <HiUserAdd size={20} />
          </Button>
        </Box>
    );
 }




  const openModalWithContent = (title: any, content: any): void => {
    setModalTitle(title);
    setModalContent(content);
    setOpenModal(true);
  };

  const handleCloseModal = (): void => {
    setOpenModal(false);
  };

  return (
      <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            width: '100%',
          }}
      >
        <div style={{width: "100%", height: "80%", marginTop: "10px"}}>
          {isFetching ? (
              <div style={{display: "flex", justifyContent: "center", alignItems: "center", height: "100%"}}>
                <Spinner/>
              </div>
          ) : (
              <AgGridReact
                  rowData={rowData}
                  columnDefs={colDefs}
                  pagination={true}
                  defaultColDef={defaultColDef}
                  paginationPageSize={20}
                  paginationPageSizeSelector={[10, 20, 50, 100]}
                  theme={appTheme.palette.mode === "dark" ? themeDarkBlue : themeLightCold}
              />
          )
          }
        </div>
        <Dialog
            open={isDialogOpen}
            onClose={handleCancel}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Confirm Delete"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to delete this category? This action cannot be
              undone.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCancel} color="primary">
              Cancel
            </Button>
            <Button onClick={handleConfirm} color="secondary" autoFocus>
              Delete
            </Button>
          </DialogActions>
        </Dialog>

<Modal
    open={openModal}
    onClose={handleCloseModal}
    aria-labelledby="modal-title"
    aria-describedby="modal-description"
>
  <Box
      sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
        width: '70%',
      }}
  >
    <IconButton
      aria-label="close"
      onClick={handleCloseModal}
      sx={{
        position: 'absolute',
        right: 8,
        top: 8,
        color: (theme) => theme.palette.grey[500],
      }}
    >
      <CloseIcon />
    </IconButton>
    <Typography id="modal-title" variant="h6" component="h2">
      {modalTitle}
    </Typography>
    <Box id="modal-description" sx={{mt: 2}}>
      {modalContent}
    </Box>
  </Box>
</Modal>
      </Box>


  );
};

export default ChatCategoryList;
