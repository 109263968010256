import { useEffect, useState } from 'react';
import { Vortex } from 'react-loader-spinner';
import './Loader.css';
import logo from '../../assets/logos/logo_white.png';

const Loader = () => {
  const [activeMessage, setActiveMessage] = useState(0);
  const [progress, setProgress] = useState(0);
  const messages = [
    {
      text: "AI Power at Your Command",
      sub: "Access GPT-4, Gemini, and Claude 3 in one place"
    },
    {
      text: "Enterprise-Grade Protection",
      sub: "Advanced encryption for your business knowledge"
    },
    {
      text: "Seamless Google Integration",
      sub: "Works perfectly with your Google Workspace"
    },
    {
      text: "Smart Team Controls",
      sub: "Give the right access to the right people"
    }
  ];

  useEffect(() => {
    const messageInterval = setInterval(() => {
      setActiveMessage(prev => (prev + 1) % messages.length);
    }, 3500);

    const progressInterval = setInterval(() => {
      setProgress(prev => Math.min(prev + Math.random() * 10, 100));
    }, 800);

    return () => {
      clearInterval(messageInterval);
      clearInterval(progressInterval);
    };
  }, []);

  return (
    <div className="neu-loader">
      <div className="neu-grid-overlay" />

      <div className="neu-core">
        <div className="neu-orbital">
          <img src={logo} width={350} alt="DatalinkerAI"/>
        </div>

        {/*<div className="neu-connection-pulse"/>*/}
      </div>

      <div className="neu-message-stack">
        <div className="neu-message-primary">
          {messages[activeMessage].text}
        </div>
        <div className="neu-message-secondary">
          {messages[activeMessage].sub}
        </div>
      </div>

      <div className="neu-progress">
        <div className="neu-progress-track">
          <div
            className="neu-progress-bar"
            style={{ width: `${progress}%` }}
          />
        </div>
        <div className="neu-progress-status">
          Building your bridge to the future of AI • v1.0
        </div>
      </div>

      <div className="neu-logo">

        <p className="powered-by">Powered by The Virtulab</p>
      </div>

      <div className="neu-particle-field">
        {[...Array(24)].map((_, i) => (
          <div key={i} className="neu-particle" />
        ))}
      </div>
    </div>
  );
};

export default Loader;