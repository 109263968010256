import React, { useEffect, useMemo, useState } from 'react';
import { useApp } from "../hooks";
import {
  MdOutlineDelete,
  MdOutlineDownload,
  MdOutlinePlayArrow,
  MdOutlineStop
} from "react-icons/md";
import {
  deleteFileService,
  downloadAudioFileService,
  getFilesListService
} from "../services/databases.service";
import {

  Button,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,

  Modal,
  Typography,
  IconButton
} from '@mui/material';

import { AllCommunityModule, ModuleRegistry, themeQuartz, colorSchemeDarkBlue, colorSchemeLightCold } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import {useTheme} from "@mui/material/styles";
import Spinner from "../components/shared/Spinner";
ModuleRegistry.registerModules([AllCommunityModule]);

const FilesList = ({ setTabs }: any) => {
  const { state, dispatch }: any = useApp();
  const [playingAudio, setPlayingAudio]: any = useState(null);
  const [playingFileName, setPlayingFileName]: any = useState(null);
  const [isDialogOpen, setIsDialogOpen]: any = useState(false);
  const [fileToDelete, setFileToDelete]: any = useState(null);
  const [openModal, setOpenModal]: any = useState(false);
  const [modalTitle, setModalTitle]: any = useState('');
  const [modalContent, setModalContent]: any = useState(null);


  // ag-grid
  const themeLightCold = themeQuartz.withPart(colorSchemeLightCold);
  const themeDarkBlue = themeQuartz.withPart(colorSchemeDarkBlue);
  const [rowData, setRowData]: any = useState([]);
  const [isFetching, setIsFetching]: any = useState(true);
  const appTheme = useTheme();
  const defaultColDef = useMemo(() => {
    return {

      flex: 1,
    };
  }, []);
  const [colDefs, setColDefs]: any = useState([]);

  const setStatus = (message: any, type: any) => {
    dispatch({
      type: 'SET_NOTIFICATION',
      payload: { show: true, title: 'Media Service', message: message, type: type }
    });
  };

  const actionCellRenderer = (params: any) => {
    return (
        <div className="flex items-center justify-center space-x-2 py-2">
          {params.data.file_type === 'mp3' && (
              <IconButton onClick={() => playFile(params.data)} color="primary">
                {playingFileName === params.data.file_name ? <MdOutlineStop size={20} /> : <MdOutlinePlayArrow size={20} />}
              </IconButton>
          )}
          <IconButton onClick={() => downloadFile(params.data)} color="primary">
            <MdOutlineDownload size={20} />
          </IconButton>
          <IconButton onClick={() => deleteFile(params.data)} color="secondary">
            <MdOutlineDelete size={20} />
          </IconButton>
        </div>
    );
  }

  useEffect(() => {
    const fetchFiles = async () => {
      if (state.selectedOrganisation?._id) {
        const files: any = await getFilesListService(state.selectedOrganisation._id);
        dispatch({ type: 'SET_FILES', payload: files?.data?.data?.audio_files || [] });
        setColDefs([
            {
                headerName: "File Name",
                field: "file_name",
                sortable: true,
                filter: true,
                floatingFilter: true,
            },
            {
                headerName: "File Type",
                field: "file_type",
                sortable: true,
                filter: true,
                floatingFilter:  true,
            },
            {
                headerName: "File Size (MB)",
                field: "file_size_mb",
                sortable: true,
                filter: true,
                floatingFilter:  true,
                type: 'numericColumn',
            },
            {
                headerName: "Time Created",
                field: "time_created",
                sortable: true,
                filter: true,
            },
          {
            headerName: "Actions",
            field: "actions",
            cellRenderer: actionCellRenderer,
            sortable: false,
            filter: false,
          }
            ]);
        setRowData(files?.data?.data?.audio_files || []);
        setIsFetching(false);
      }
    };
    fetchFiles();
  }, [state.selectedOrganisation, dispatch]);

  const deleteFile = async (data: any) => {
    const { file_name } = data;
    setFileToDelete(file_name);
    setIsDialogOpen(true);
  };

  const handleDatabaseDeleteConfirm = async () => {
    if (fileToDelete) {
      const { success, message } = await deleteFileService(fileToDelete, state.selectedOrganisation._id);
      if (success === 1) {
        setStatus("File deleted successfully", "success");
        const files: any = await getFilesListService(state.selectedOrganisation._id);
        dispatch({ type: 'SET_FILES', payload: files?.data?.data?.audio_files || [] });
      } else {
        setStatus(message, "error");
      }
      setIsDialogOpen(false);
    }
  };

  const handleCancel = () => {
    setIsDialogOpen(false);
    setFileToDelete(null);
  };

  const downloadFile = async (data: any) => {
    const { file_name } = data;
    const audio_file_data: any = await downloadAudioFileService(file_name, state.selectedOrganisation._id);
    if (audio_file_data?.data?.data?.audio_url) {
      const file_path = audio_file_data?.data?.data?.audio_url;
      const link = document.createElement('a');
      link.href = file_path;
      link.download = file_name;
      link.click();
    } else {
      setStatus("File not found", "error");
    }
  };

  const playFile = async (data: any) => {
    const { file_name }: any = data;
    if (playingAudio) {
      playingAudio.pause();
      playingAudio.currentTime = 0;
    }
    if (playingFileName === file_name) {
      setPlayingAudio(null);
      setPlayingFileName(null);
      return;
    }
    const audio_file_data: any = await downloadAudioFileService(file_name, state.selectedOrganisation._id);
    if (audio_file_data?.data?.data?.audio_url) {
      const file_path = audio_file_data?.data?.data?.audio_url;
      const audio = new Audio(file_path);
      audio.play();
      setPlayingAudio(audio);
      setPlayingFileName(file_name);
    } else {
      setStatus("File not found", "error");
    }
  };

  return (
      <div className="flex flex-col" style={{maxHeight: "75dvh", overflow: "auto"}}>
        <Typography variant="h5" sx={{mb: 2}}>Media</Typography>
        <hr style={{border: "1px solid #e0e0e0", width: "100%"}}/>
        <div style={{width: "100%", height: "80vh", marginTop: "10px"}}>
          {isFetching ? (
              <div style={{display: "flex", justifyContent: "center", alignItems: "center", height: "100%"}}>
                <Spinner/>
              </div>
          ) : (
              <AgGridReact
                  rowData={rowData}
                  columnDefs={colDefs}
                  pagination={true}
                  defaultColDef={defaultColDef}
                  paginationPageSize={20}
                  paginationPageSizeSelector={[10, 20, 50, 100]}
                  theme={appTheme.palette.mode === "dark" ? themeDarkBlue : themeLightCold}
              />
          )
          }
        </div>

        <Dialog
            open={isDialogOpen}
            onClose={handleCancel}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Confirm Delete"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to delete this file? This action cannot be undone.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCancel} color="primary">
              Cancel
            </Button>
            <Button onClick={handleDatabaseDeleteConfirm} color="secondary" autoFocus>
              Delete
            </Button>
          </DialogActions>
        </Dialog>
        <Modal
            open={openModal}
            onClose={() => setOpenModal(false)}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
        >
          <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                bgcolor: "background.paper",
                boxShadow: 24,
                p: 4,
                width: '70%',
              }}
          >
            <Typography id="modal-title" variant="h6" component="h2">
              {modalTitle}
            </Typography>
            <Box id="modal-description" sx={{mt: 2}}>
              {modalContent}
            </Box>
          </Box>
        </Modal>
      </div>
  );
};

export default FilesList;
