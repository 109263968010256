import React, { useEffect, useState, useMemo } from "react";
import { useApp, useAuth } from "../../hooks";
import { FaUserTimes } from "react-icons/fa";
import { dismissCategoryService } from "../../services/category.service";
import { getUsersInfoByIds } from "../../services/auth.service";
import CircularProgress from '@mui/material/CircularProgress';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import Paper from '@mui/material/Paper';
import TableRow from '@mui/material/TableRow';
import TableHead from '@mui/material/TableHead';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';

const ChatCategoryUsers = ({ category }: any) => {
  const { state, dispatch }: any = useApp();
  const [categoryUsers, setCategoryUsers] = useState([]);
  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 10 });
  const [loading, setLoading] = useState(true);
  const { auth, setAuth }: any  = useAuth();

  const fetchCategoryUsers = async () => {
    setLoading(true);
    try {
      const response: any = await getUsersInfoByIds(category?.users, pagination.pageSize, pagination.pageIndex + 1);
      if (response.status) {
        console.log(response.data.data);
        setCategoryUsers(response.data.data);
      } else {
        setStatus(response.data.message, 'error');
      }
    } catch (error) {
      setStatus("Failed to fetch users", 'error');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCategoryUsers();
  }, [category, pagination]);

  const removeUserFromCategory = async (user: any) => {
    const payload = {
      user_id: user.user_id,
      category_name: category.category_name,
      organisation_id: state.selectedOrganisation._id
    };
    const { status, message } = await dismissCategoryService(payload);
    if (status) {
      const newCategoryUsers = categoryUsers.filter((cu: any) => cu.user_id !== user.user_id);
      setCategoryUsers(newCategoryUsers);
      setStatus(message, 'success');
    } else {
      setStatus(message, 'error');
    }
  };

  const setStatus = (message: string, type: string) => {
    dispatch({
      type: 'SET_NOTIFICATION',
      payload: { show: true, title: 'Chat Topic Update', message: message, type: type }
    });
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: 'display_name',
        header: 'Name',
        cell: (info: any) => info?.row?.display_name,
      },
      {
        accessorKey: 'email',
        header: 'Email',
        cell: (info: any) => info?.row?.email,
      },
      {
        accessorKey: 'action',
        header: 'Action',
        cell: ({ row }: any) => (
          <div className='flex gap-2'>
            {row?.user_id !== auth.user.uid && (
              <Button onClick={() => removeUserFromCategory(row)}>
                <FaUserTimes size={25} color="black" />
              </Button>
            )}
          </div>
        ),
      },
    ],
    [categoryUsers]
  );

  const handlePageChange = (page: number) => {
    setPagination((prev) => ({ ...prev, pageIndex: page - 1 }));
  };

  return (
    <TableContainer component={Paper} elevation={3} sx={{ marginTop: 2 }}>
      <Table>
        <TableHead>
          <TableRow>
            {columns.map((column: any) => (
              <TableCell key={column.accessorKey} sx={{ fontWeight: "bold" }}>
                {column.header}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {loading ? (
            <TableRow>
              <TableCell colSpan={100} style={{ textAlign: "center" }}>
                <CircularProgress />
              </TableCell>
            </TableRow>
          ) : (
            categoryUsers.map((row: any, rowIndex: number) => (
              <TableRow key={rowIndex} hover>
                {columns.map((column: any) => (
                  <TableCell key={column.accessorKey}>
                    {column.cell({ row })}
                  </TableCell>
                ))}
              </TableRow>
            ))
          )}
        </TableBody>
      </Table>
      <div
        className="pagination"
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "16px",
        }}
      >
        <LoadingButton
          loading={loading}
          variant="outlined"
          onClick={() => handlePageChange(pagination.pageIndex)}
          disabled={pagination.pageIndex === 0}
        >
          {"<"}
        </LoadingButton>
        <span>
          Page {pagination.pageIndex + 1}
        </span>
        <LoadingButton
          loading={loading}
          variant="outlined"
          onClick={() => handlePageChange(pagination.pageIndex + 2)}
          disabled={categoryUsers.length < pagination.pageSize}
        >
          {">"}
        </LoadingButton>
      </div>
    </TableContainer>
  );
};

export default ChatCategoryUsers;
