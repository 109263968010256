import { FaEdit } from "react-icons/fa";
import { useApp } from "../../hooks";
import OrganisationCreateForm from "./OrganisationCreateForm";
import {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";

const OrganisationSettings = () => {
  const { state, dispatch }: any = useApp();

  const [orgEdit, setOrgEdit] = useState(true);

  const handleStatus = () => {
    setOrgEdit(false)
  }



  return (
    <div>
      <h1 className="flex gap-4 items-center"> {state.selectedOrganisation.name} <FaEdit onClick={() => setOrgEdit(!orgEdit)} /></h1>
      {
        <div className="mt-4">
          <OrganisationCreateForm action='update' payload={state.selectedOrganisation} handleStatus={handleStatus} />
        </div>
      }
    </div>
  )
}

export default OrganisationSettings;