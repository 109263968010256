import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../hooks";
import { sendVerificationEmail } from "../services/auth.service";
import {
    Box,
    Modal,
    Typography,
    Button,
    Alert,
    CircularProgress,
    LinearProgress,
    Grid,
    useTheme,
    styled,
    Paper
} from "@mui/material";
import { CheckCircleOutline, EmailOutlined } from "@mui/icons-material";

const StyledModal = styled(Modal)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
}));

const VerificationCard = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(4),
    maxWidth: 500,
    width: "100%",
    textAlign: "center",
    "& .MuiAlert-root": {
        margin: theme.spacing(2, 0),
    },
}));

const VerifyEmailPage = () => {
    const navigate = useNavigate();
    const theme = useTheme();
    const { auth }: any = useAuth();
    const [timer, setTimer] = useState(0);
    const [loading, setLoading] = useState(false);
    const [status, setStatus] = useState<"idle" | "success" | "error">("idle");
    const [message, setMessage] = useState("");

    useEffect(() => {
        if (auth?.user?.email_verified) {
            navigate("/organisation/:orgId/category/:categoryIdParam/thread/:threadIdParam");
        }
    }, [auth, navigate]);

    useEffect(() => {
        let interval: NodeJS.Timeout;
        if (timer > 0) {
            interval = setInterval(() => {
                setTimer((prev) => prev - 1);
            }, 1000);
        }
        return () => clearInterval(interval);
    }, [timer]);

    const handleSendVerification = async () => {
        setLoading(true);
        setStatus("idle");
        try {
            await sendVerificationEmail(auth.user.email);
            setStatus("success");
            setMessage("Verification email sent successfully. Please check your inbox.");
            setTimer(120);
        } catch (error) {
            setStatus("error");
            setMessage("Failed to send verification email. Please try again later.");
        }
        setLoading(false);
    };

    const formatTime = (seconds: number) => {
        const mins = Math.floor(seconds / 60);
        const secs = seconds % 60;
        return `${mins}:${secs.toString().padStart(2, "0")}`;
    };

    return (
        <StyledModal open >
            <VerificationCard elevation={3}>
                <Box mb={4}>
                    <CheckCircleOutline
                        sx={{
                            fontSize: 64,
                            color: theme.palette.primary.main,
                            mb: 2,
                        }}
                    />
                    <Typography variant="h4" gutterBottom>
                        Verify Your Email Address
                    </Typography>
                    <Typography variant="body1" color="textSecondary">
                        We've sent a verification link to
                    </Typography>
                    <Typography variant="subtitle1" fontWeight="bold">
                        {auth?.user?.email}
                    </Typography>
                </Box>

                {status === "success" && (
                    <Alert severity="success" icon={false}>
                        {message}
                        {timer > 0 && (
                            <LinearProgress
                                variant="determinate"
                                value={(timer / 120) * 100}
                                sx={{ mt: 1 }}
                            />
                        )}
                    </Alert>
                )}

                {status === "error" && (
                    <Alert severity="error" icon={false}>
                        {message}
                    </Alert>
                )}

                <Grid container spacing={2} mt={2}>
                    <Grid item xs={12}>
                        <Button
                            fullWidth
                            variant="contained"
                            size="large"
                            onClick={handleSendVerification}
                            disabled={loading || timer > 0}
                            startIcon={
                                loading ? (
                                    <CircularProgress size={20} color="inherit" />
                                ) : (
                                    <EmailOutlined />
                                )
                            }
                        >
                            {timer > 0
                                ? `Resend in ${formatTime(timer)}`
                                : "Send Verification Email"}
                        </Button>
                    </Grid>

                    <Grid item xs={12}>
                        <Button
                            fullWidth
                            variant="outlined"
                            color="success"
                            size="large"
                            onClick={() => window.location.reload()}
                            sx={{ textTransform: "none" }}
                        >
                            I've verified my email - Continue to App
                        </Button>
                    </Grid>

                    <Grid item xs={12}>
                       <Typography
    variant="body2"
    color="textSecondary"
    mt={2}
    sx={{ opacity: 0.8 }}
>
    Didn't receive the email? Check your spam folder or contact <a href="mailto:info@datalinker.ai">support</a>
</Typography>
                    </Grid>
                </Grid>
            </VerificationCard>
        </StyledModal>
    );
};

export default VerifyEmailPage;