import { useEffect, useState } from "react";
import { FaDatabase, FaPlusCircle } from "react-icons/fa";
import DatabasesList from "../components/databases/DatabasesList";
import { useApp, useAuth } from "../hooks";
import AddDatabase from "../components/databases/AddDatabase";
import { getDatabasesService } from "../services/databases.service";
import { checkIfUserIsAdmin, checkIfUserIsOwner } from "../services/auth.service";
import { useNavigate } from "react-router-dom";
import {Box, Tab, Typography, useMediaQuery, useTheme, styled, Paper, Tabs} from '@mui/material';

const DatabasesPage = () => {
  const { state, dispatch }: any = useApp();
  const [selectedTab, setSelectedTab] = useState(0);
  const { auth }: any = useAuth();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    if (state.selectedOrganisation?._id) {
      const { role } = state.selectedOrganisation.members.find((m: any) => m.user_id === auth.user.user_id);
      if ((role === 'owner' || role === 'admin') && (state.selectedOrganisation?.subscription_status !== 'active')) {
        navigate(`/organisation/${state.selectedOrganisation._id}/billing`);
        dispatch({
          type: "SET_NOTIFICATION",
          payload: {
            show: true,
            title: 'Subscription',
            message: 'Your subscription is currently inactive. Please check your account settings.',
            type: 'error'
          }
        });
      }
      if (checkIfUserIsAdmin(state.selectedOrganisation, auth.user.user_id) || checkIfUserIsOwner(state.selectedOrganisation, auth.user.user_id)) {
        setInitialData();
      } else {
        dispatch({
          type: 'SET_NOTIFICATION',
          payload: {
            show: true,
            title: 'Authorization',
            message: 'You are not authorized!',
            type: 'warning'
          }
        });
        navigate(`/organisation/${state.selectedOrganisation._id}/category/1/thread/1`);
      }
    }
  }, [state.selectedOrganisation]);

  const setInitialData = async () => {
    if (state.selectedOrganisation?._id) {
      const databases = await getDatabasesService(state.selectedOrganisation._id, null, null, null, null);
      if (databases.response?.length > 0) {
        dispatch({ type: 'SET_DATABASES', payload: databases.response });
      } else {
        setSelectedTab(1);
      }
    }
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  const StyledTabs = styled(Tabs)(({ theme }) => ({
    '& .MuiTabs-indicator': {
      backgroundColor: theme.palette.primary.main,
      height: 3,
    },
  }));

  const StyledTab = styled(Tab)(({ theme }) => ({
    minWidth: 'unset',
    padding: theme.spacing(1.5, 2),
    fontSize: isMobile ? '0.875rem' : '1rem',
    '&.Mui-selected': {
      color: theme.palette.primary.main,
    },
  }));

  return (
      <Paper sx={{
        mx: 'auto',
        minHeight: '80vh',
        height: '100%',
        p: { xs: 1, sm: 3 },
        borderRadius: 2,
        boxShadow: theme.shadows[2],
        backgroundColor: 'background.paper',
        overflow: 'auto'
      }}>
        <Box sx={{ mb: 3 }}>

          <StyledTabs
              value={selectedTab}
              onChange={handleChange}
              variant={isMobile ? 'scrollable' : 'standard'}
              scrollButtons="auto"
          >
            <StyledTab
                icon={<FaDatabase fontSize={isMobile ? '1rem' : '1.2rem'} />}
                label={isMobile ? null : 'Drives'}
                iconPosition="start"
            />
            <StyledTab
                icon={<FaPlusCircle fontSize={isMobile ? '1rem' : '1.2rem'} />}
                label={isMobile ? null : 'New Drive'}
                iconPosition="start"
            />
          </StyledTabs>
        </Box>

        <Box sx={{
          pt: 2,
          minHeight: 400,
          transition: 'all 0.3s ease',
          height: '100%',
        }}>
          {selectedTab === 0 && <DatabasesList setTabs={setSelectedTab} />}
          {selectedTab === 1 && <AddDatabase setTabs={setSelectedTab} />}
        </Box>
      </Paper>
  );
};

export default DatabasesPage;