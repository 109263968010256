import { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useApp, useAuth } from "../../hooks";
import { styled, useTheme } from "@mui/material/styles";
import {
  List,
  ListItemIcon,
  ListItemText,
  Box,
  Typography,
  ListItemButton,
  Collapse,
} from "@mui/material";
import {
  ExpandLess,
  ExpandMore,
  Settings,
  Group,
  Payments,
  Home,
  Business,
  Forum,
  Storage,
  InsertDriveFile,
  Build
} from "@mui/icons-material";

const MenuContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '100%',
  backgroundColor: theme.palette.background.paper,
  display: 'flex',
  flexDirection: 'column',
  borderRight: `1px solid ${theme.palette.divider}`,
}));



const AdminMenu = () => {
  const theme = useTheme();
  const { pathname } = useLocation();
  const { state }: any = useApp();
  const { auth }: any = useAuth();
  const navigate = useNavigate();
  const { selectedOrganisation } = state;
  const [openSections, setOpenSections] = useState<Record<string, boolean>>({
    organization: true
  });

  const menuItems = [
    { path: `${selectedOrganisation._id}/category/1/thread/1`, icon: <Home />, text: 'Home' },
    {
      text: 'Organization',
      icon: <Business />,
      subItems: [
        { path: `${selectedOrganisation._id}/settings`, icon: <Settings />, text: 'Settings' },
        { path: `${selectedOrganisation._id}/users`, icon: <Group />, text: 'Users' },
        { path: `${selectedOrganisation._id}/billing`, icon: <Payments />, text: 'Billing' },
      ]
    },
    { path: `${selectedOrganisation._id}/chat-category`, icon: <Forum />, text: 'Chat Topics' },
    { path: `${selectedOrganisation._id}/databases`, icon: <Storage />, text: 'Drives' },
    { path: `${selectedOrganisation._id}/files`, icon: <InsertDriveFile />, text: 'Media' },
    { path: `${selectedOrganisation._id}/tools`, icon: <Build />, text: 'Tools' },
  ];

  const menuStyles = {
    header: {
      px: 2,
      py: 1,
      '&:hover': {
        backgroundColor: theme.palette.action.hover,
      }
    },
    listItem: {
      px: 2,
      py: '6px',
      borderRadius: 1,
      '&.Mui-selected': {
        backgroundColor: theme.palette.action.selected,
        '&:hover': {
          backgroundColor: theme.palette.action.selected,
        }
      }
    },
    icon: {
      minWidth: '36px',
      color: theme.palette.text.secondary
    },
    subItem: {
      pl: 4,
      py: '4px',
      '& .MuiListItemIcon-root': {
        minWidth: '32px'
      }
    }
  };

  const toggleSection = (section: string) => {
    setOpenSections(prev => ({ ...prev, [section]: !prev[section] }));
  };

  const isActive = (path: string) => {
    return pathname.includes(path);
  };

  const handleNavigation = (path: string) => {
    navigate(`/organisation/${path}`);
  };

  const renderMenuItems = () => (
      <List component="nav" sx={{ flex: 1, overflow: 'auto' }}>
        {menuItems.map((item, index) => (
            <div key={index}>
              {item.path ? (
                  <ListItemButton
                      onClick={() => handleNavigation(item.path)}
                      selected={isActive(item.path)}
                      sx={menuStyles.listItem}
                  >
                    <ListItemIcon sx={menuStyles.icon}>
                      {item.icon}
                    </ListItemIcon>
                    <ListItemText
                        primary={
                          <Typography variant="body2" fontWeight={500}>
                            {item.text}
                          </Typography>
                        }
                    />
                  </ListItemButton>
              ) : (
                  <>
                    <ListItemButton
                        onClick={() => toggleSection('organization')}
                        sx={menuStyles.header}
                    >
                      <ListItemIcon sx={menuStyles.icon}>
                        {item.icon}
                      </ListItemIcon>
                      <ListItemText
                          primary={
                            <Typography variant="body2" fontWeight={500}>
                              {item.text}
                            </Typography>
                          }
                      />
                      {openSections.organization ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                    <Collapse in={openSections.organization}>
                      {item.subItems?.map((subItem, subIndex) => (
                          <ListItemButton
                              key={subIndex}
                              onClick={() => handleNavigation(subItem.path)}
                              selected={isActive(subItem.path)}
                              sx={menuStyles.subItem}
                          >
                            <ListItemIcon>{subItem.icon}</ListItemIcon>
                            <ListItemText
                                primary={
                                  <Typography variant="body2">
                                    {subItem.text}
                                  </Typography>
                                }
                            />
                          </ListItemButton>
                      ))}
                    </Collapse>
                  </>
              )}
            </div>
        ))}
      </List>
  );

  return (
      <MenuContainer>
        <Box sx={{ px: 2, py: 3, borderBottom: `1px solid ${theme.palette.divider}` }}>
          <Typography variant="h6" fontWeight={600} noWrap>
            {selectedOrganisation?.name || 'Organization'}
          </Typography>
          <Typography variant="body2" color="text.secondary" noWrap>
            Administration Panel
          </Typography>
        </Box>

        {renderMenuItems()}

        <Box sx={{ px: 2, py: 1.5, mt: 'auto', borderTop: `1px solid ${theme.palette.divider}` }}>
          <Typography variant="body2" color="text.secondary">
            v1.0.0
          </Typography>
        </Box>
      </MenuContainer>
  );
}

export default AdminMenu;