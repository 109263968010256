import React, {useCallback, useEffect, useMemo, useState} from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useApp } from '../../hooks';
import { accept_join_request, get_all_pending_requests, get_user_invitation_link, reject_join_request, removeUserFromOrgService } from '../../services/organisation.service';
import EditUserInfoForm from './EditUserInfoForm';
import Tooltip from '@mui/material/Tooltip';
import { getUsersInfo } from '../../services/auth.service';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';


import { ModuleRegistry, themeQuartz, colorSchemeDarkBlue, colorSchemeLightCold, InfiniteRowModelModule } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import {useTheme} from "@mui/material/styles";
import Spinner from "../shared/Spinner";
import {useParams} from "react-router-dom";
ModuleRegistry.registerModules([InfiniteRowModelModule]);


const fetchUsers = async (orgId: any, pagination: any): Promise<any> => {
  const response: any = await getUsersInfo(orgId, pagination.pageSize, pagination.pageIndex);
  return response?.data;
};

const OrganisationUsers: React.FC<any> = () => {
  const { state, dispatch }: any = useApp();
  const [activeTab, setActiveTab]: any = useState(0);
  const [editUserData, setEditUserData]: any = useState(null);
  const [isModalOpen, setIsModalOpen]: any = useState(false);
  const [isDialogOpen, setIsDialogOpen]: any = useState(false);
  const [userToDelete, setUserToDelete]: any = useState(null);


  // ag-grid
  const themeLightCold = themeQuartz.withPart(colorSchemeLightCold);
  const themeDarkBlue = themeQuartz.withPart(colorSchemeDarkBlue);
  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 10 });
  const appTheme = useTheme();
  const defaultColDef = useMemo(() => {
    return {

      flex: 1,

    };
  }, []);

    const actionCellRenderer = (params: any) => {
        return (
            <div className="flex items-center justify-center space-x-2 py-2">
                <Tooltip title="Delete User">
                    <Button onClick={() => handleOpenDialog(params.data)}>
                        <DeleteIcon />
                    </Button>
                </Tooltip>
                <Tooltip title="Edit User">
                    <Button onClick={() => handleUserEdit(params.data)}>
                        <EditIcon />
                    </Button>
                </Tooltip>
            </div>
        );
    }

  const [colDefs, setColDefs]: any = useState([
      {
          headerName: 'Index',
          field: 'id',
          sortable: false,
          filter: false,
          flex: 0.4
          // floatingFilter: false,
      },
      {
          headerName: 'Name',
          field: 'userInfo.display_name',
          sortable: false,
          filter: false,
          flex: 1.3,
          // floatingFilter: false,
      },
      {
          headerName: 'Email',
          field: 'userInfo.email',
          sortable: false,
          filter: false,
          flex: 1.3,
          // floatingFilter: false,
      },
      {
          headerName: 'Role',
          field: 'role',
          sortable: false,
          filter: false,
          // floatingFilter: false,
      },
      {
          headerName: 'Action',
          cellRenderer: actionCellRenderer,
          sortable: false,
          autoHeight: true
          // filter: false,
      },
  ]);






    const onGridReadyForMembers = useCallback((params: any) => {
        const { pageSize } = pagination;
        const { selectedOrganisation } = state;

        const dataSource = {
            rowCount: undefined,
            getRows: async (params: any) => {
                console.log("asking for " + params.startRow + " to " + params.endRow);

                const currentPageNumber = Math.floor(params.endRow / pageSize);
                let lastRow = -1;

                try {
                    const data = await fetchUsers(selectedOrganisation._id, {
                        pageIndex: currentPageNumber,
                        pageSize: pageSize,
                    });

                    let list = data.data;

                    list.forEach((item: any, index: number) => {
                        item.id = (params.startRow + index + 1);
                    });

                    if (list.length < pageSize) {
                        lastRow = params.startRow + list.length;
                    }

                    params.successCallback(list, lastRow);
                } catch (error) {
                    console.error("Error fetching data: ", error);
                    params.failCallback();
                }
            },
        };

        params.api.setGridOption("datasource", dataSource);
    }, [pagination.pageSize, state.selectedOrganisation._id]);



    const requestsColDefs: any = useMemo(
        () => [
            {
                headerName: 'Name',
                field: 'user_info.display_name',
                sortable: false,
                filter: false,
                floatingFilter: false,
            },
            {
                headerName: 'Email',
                field: 'user_info.email',
                sortable: false,
                filter: false,
                floatingFilter: false,
            },
            {
                headerName: 'Requested On',
                field: 'requested_on',
                sortable: false,
                filter: false,
                floatingFilter: false,
            },
            {
                headerName: 'Action',
                cellRenderer: (params: any) => {
                    return (
                        <div className="flex gap-4">
                            <Tooltip title="Accept User">
                                <Button onClick={() => handleUserAccept(params.data)}>
                                    <CheckCircleIcon />
                                </Button>
                            </Tooltip>
                            <Tooltip title="Reject User">
                                <Button onClick={() => handleUserReject(params.data)}>
                                    <CancelIcon />
                                </Button>
                            </Tooltip>
                        </div>
                    );
                },
                sortable: false,
                filter: false,
                autoHeight: true
            },
        ],
        []
    );


  const handleUserAccept = async (userData: any): Promise<void> => {
    const response: any = await accept_join_request(userData.user_id, state.selectedOrganisation._id);
    if (response?.data.success === 1) {
      dispatch({
        type: "SET_NOTIFICATION",
        payload: {
          show: true,
          title: "Accept User",
          message: "User accepted successfully",
          type: "success",
        },
      });
      const pendingRequests: any = await get_all_pending_requests(state.selectedOrganisation._id);
      if (pendingRequests?.data.success === 1) {
        dispatch({ type: "SET_PENDING_REQUESTS", payload: pendingRequests?.data?.data });
      }
    } else {
      dispatch({
        type: "SET_NOTIFICATION",
        payload: {
          show: true,
          title: "Accept User",
          message: response?.data.message,
          type: "error",
        },
      });
    }
  };

  const handleUserReject = async (userData: any): Promise<void> => {
    const response: any = await reject_join_request(userData.user_id, state.selectedOrganisation._id);
    if (response?.data.success === 1) {
      dispatch({
        type: "SET_NOTIFICATION",
        payload: {
          show: true,
          title: "Reject User",
          message: "User join request rejected",
          type: "success",
        },
      });
      const pendingRequests: any = await get_all_pending_requests(state.selectedOrganisation._id);
      if (pendingRequests?.data.success === 1) {
        dispatch({ type: "SET_PENDING_REQUESTS", payload: pendingRequests?.data?.data });
      }
    } else {
      dispatch({
        type: "SET_NOTIFICATION",
        payload: {
          show: true,
          title: "Reject User",
          message: response?.data.message,
          type: "error",
        },
      });
    }
  };



  useEffect((): void => {
    const fetchPendingRequests = async (): Promise<void> => {
      const pendingRequests: any = await get_all_pending_requests(state.selectedOrganisation._id);
      if (pendingRequests?.data.success === 1) {
        dispatch({ type: "SET_PENDING_REQUESTS", payload: pendingRequests?.data?.data });
      }
    };
    fetchPendingRequests();
  }, [state.selectedOrganisation._id]);

  const handleOpenDialog = (userData: any): void => {
    setUserToDelete(userData);
    setIsDialogOpen(true);
  };

  const handleCloseDialog = (): void => {
    setIsDialogOpen(false);
    setUserToDelete(null);
  };

  const handleDeleteUser = async (): Promise<void> => {
    if (userToDelete) {
      await handleOrgUserDelete(userToDelete);
      handleCloseDialog();
    }
  };

  const handleOrgUserDelete = async (userData: any): Promise<void> => {
    try {
      if (userData.role === "owner") {
        dispatch({
          type: "SET_NOTIFICATION",
          payload: {
            show: true,
            title: "Remove User",
            message: "You cannot remove the owner",
            type: "error",
          },
        });
      } else {
        const { status, message }: any = await removeUserFromOrgService(userData.user_id, state.selectedOrganisation._id);
        if (status) {
          dispatch({
            type: "SHOW_MODAL",
            payload: { show: false, title: "", component: null, className: "" },
          });
          dispatch({
            type: "SET_NOTIFICATION",
            payload: {
              show: true,
              title: "Remove User",
              message: "User removed successfully",
              type: "success",
            },
          });
        } else {
          dispatch({
            type: "SET_NOTIFICATION",
            payload: {
              show: true,
              title: "Remove User",
              message: message,
              type: "error",
            },
          });
        }
      }
    } catch (error: any) {
      dispatch({
        type: "SET_NOTIFICATION",
        payload: {
          show: true,
          title: "Remove User",
          message: "Something went wrong!",
          type: "error",
        },
      });
    }
  };

  const handleUserEdit = (userData: any): void => {
    setEditUserData(userData);
    setIsModalOpen(true);
  };

  const handleCloseModal = (): void => {
    setIsModalOpen(false);
    setEditUserData(null);
  };

  const copyInviteLink = async (): Promise<void> => {
    const inviteLink: any = await get_user_invitation_link(state.selectedOrganisation._id);
    navigator.clipboard.writeText(inviteLink);
    dispatch({
      type: "SET_NOTIFICATION",
      payload: {
        show: true,
        title: "Copy Link",
        message: "Link copied to clipboard",
        type: "success",
      },
    });
  };

  const [pendingRequestData, setPendingRequestData]: any = useState([]);

    useEffect(() => {
        console.log("Pending Requests: ", state.pendingRequests);
        if(state?.pendingRequests?.length > 0)
            setPendingRequestData(state.pendingRequests);
    }, [state.pendingRequests]);

  const renderContent = (): any => {
    if (activeTab === 0) {
      return (

          <div style={{width: "100%", height: "80vh", marginTop: "10px"}}>
                  <AgGridReact
                      key="members"
                      columnDefs={colDefs}
                      defaultColDef={defaultColDef}
                      onGridReady={onGridReadyForMembers}
                      rowModelType="infinite"
                      cacheBlockSize={pagination.pageSize}
                      theme={appTheme.palette.mode === 'dark' ? themeDarkBlue : themeLightCold}
                      pagination={false}
                  />

            </div>

      );
    } else if (activeTab === 1) {
        return (
            <div style={{ width: '100%', height: '80vh', marginTop: '10px' }}>
                {state.pendingRequests ? (
                    <AgGridReact
                        key="pending-requests"
                        rowData={pendingRequestData}
                        columnDefs={requestsColDefs}
                        defaultColDef={defaultColDef}
                        onGridReady={() => {}}
                        rowModelType="clientSide"
                        theme={appTheme.palette.mode === 'dark' ? themeDarkBlue : themeLightCold}
                        pagination={true}
                        paginationPageSize={10}
                        paginationPageSizeSelector={[10, 20, 50, 100]}
                        noRowsOverlayComponent={() => {return <div>No pending requests</div>}}
                    />
                ) : (
                    <Spinner />
                )}
            </div>
        );
    }
  };

  const handleTabChange = (event: any, newValue: any): void => {
    setActiveTab(newValue);
  };

  return (
      <div className="flex flex-col">
        <div className="flex items-center justify-between">
          <Tabs value={activeTab} onChange={handleTabChange}>
            <Tab label="Members" />
            <Tab label="Join Requests" />
          </Tabs>
          <div className="flex justify-end items-center space-x-2">
            <Button variant="contained" color="secondary" onClick={copyInviteLink}>
              <ContentCopyIcon sx={{ marginRight: 1 }} />
              Copy Invitation Link
            </Button>
          </div>
        </div>
        {renderContent()}
        <Modal
            open={isModalOpen}
            onClose={handleCloseModal}
            aria-labelledby="edit-user-modal-title"
            aria-describedby="edit-user-modal-description"
        >
          <Box
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 400,
                bgcolor: 'background.paper',
                boxShadow: 24,
                p: 4,
              }}
          >
            {editUserData && <EditUserInfoForm userData={editUserData} />}
          </Box>
        </Modal>
        <Dialog
            open={isDialogOpen}
            onClose={handleCloseDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Confirm Delete"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to delete this user? This action cannot be undone.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog} color="primary">
              Cancel
            </Button>
            <Button onClick={handleDeleteUser} color="secondary" autoFocus>
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      </div>
  );
};


export default OrganisationUsers;
