import Modal from '@mui/material/Modal';
import { useApp } from '../../hooks';
import OrganisationCreateForm from './OrganisationCreateForm';
import {useTheme} from "@mui/material/styles";

const OrganisationCreateModel = () => {
  const { state }: any = useApp()
  const theme = useTheme();

  return (
   <Modal open={state.isOrganisationNotExist}>
  <div className="p-8" style={{ backgroundColor: theme.palette.background.paper, height: '100vh' }}>
    <h1 className='text-xl font-bold'>Create your organisation</h1>
    <div className='mt-8'>
     <OrganisationCreateForm action="create" />
    </div>
  </div>
</Modal>
  )
}

export default OrganisationCreateModel