import { useEffect, useState } from "react";
import { getIcon } from "../../components/utils";
import { useApp, useAuth } from "../../hooks";
import SettingsPage from "../../pages/SettingsPage";
import Avatar from "@mui/material/Avatar";
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { DarkModeToggle } from "../../App";
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Menu from "@mui/material/Menu";
import Fade from "@mui/material/Fade";
import { useNavigate, useParams } from "react-router-dom";
import Divider from "@mui/material/Divider";
import SettingsIcon from '@mui/icons-material/Settings';
import ListItemIcon from '@mui/material/ListItemIcon';
import EmailIcon from '@mui/icons-material/Email';
import Tooltip from '@mui/material/Tooltip';
import Logout from '@mui/icons-material/Logout';
import logo_white from '../../assets/logos/logo_white.png'
import {Typography} from "@mui/material";

const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

const Header = ({ children, outlet }: any) => {
  const { state, dispatch }: any = useApp();
  const navigate = useNavigate();
  const { auth, setAuth }: any = useAuth();
  const [profilePhoto, setProfilePhoto] = useState('');
  const { selectedOrganisation } = state;
  const theme = useTheme();
  const [open, setOpen] = useState(true);
  const { threadIdParam, categoryIdParam, orgId }: any = useParams();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const orgOpenMenu = Boolean(anchorEl);
  const handleOrgMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleOrgMenuClose = () => {
    setAnchorEl(null);
  };

  const GradientMenu = styled(Menu)({
    '& .MuiPaper-root': {
      background: 'linear-gradient(to bottom right, #C0B7EA, #B4C5F2)',
      borderRadius: '5px',
      width: '220px'
    },
  });

  const GradientMenuItem = styled(MenuItem)({
    backgroundColor: theme.palette.background.paper,
    margin: '4px',
    borderRadius: '5px',
    '&:hover': {
      backgroundColor: theme.palette.background.default,
    },
  });

  useEffect(() => {
    if (auth?.user) {
      const image = getIcon(auth.user.email.split('')[0].toUpperCase());
      setProfilePhoto(image);
    }
  }, [auth]);

  const handleLogout = () => {
    window.localStorage.removeItem('idToken');
    window.localStorage.removeItem('refreshToken');
    setAuth({});
    navigate('/auth/login');
  };

  const handleProfile = () => {
    dispatch({
      type: 'SHOW_MODAL',
      payload: {
        show: true,
        title: 'Profile & Settings',
        component: <SettingsPage />,
        className: 'model-md'
      }
    });
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const onChangeOrg = (org: any) => {
    if (org._id !== state.selectedOrganisation._id && threadIdParam && categoryIdParam && orgId) {
      window.location.href = `/organisation/${org._id}/category/1/thread/1`;
    } else {
      const lastSegment = window.location.pathname.split('/').pop();
      window.location.href = `/organisation/${org._id}/${lastSegment}`;
    }
  };

  const handleOrganisatioSettings = () => {
    const { role } = state.selectedOrganisation.members.find((m: any) => m.user_id === auth.user.user_id)
    if (role === 'owner' || role === 'admin') {
      navigate(`/organisation/${state.selectedOrganisation._id}/settings`)
    } else {
      dispatch({
        type: 'SET_NOTIFICATION',
        payload: { show: true, title: 'Authorization', message: 'You are not authorized!', type: 'warning' }
      })
      navigate(`/organisation/${state.selectedOrganisation._id}/category/1/thread/1`)
    }
  }

  const hasThread = window.location.pathname.includes('/thread/');

  if (!hasThread) {
  return (
    <Box sx={{ display: 'flex', width: '100%', height: '100%' }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        open={open}
        sx={{
          background: theme.palette.gradients.primary,
          ":hover": {
            background: theme.palette.gradients.primaryHover
          },
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(open && { display: 'none' }) }}
          >
            <MenuIcon />
          </IconButton>

          <DarkModeToggle />
          <div className="header-section flex py-2 px-4 relative items-center w-full justify-between text-white">
            <div className="flex items-center">
              <a href={'/'}>
                <img
                    src={
                      theme.palette.mode === 'dark'
                          ? selectedOrganisation?.org_dark_logo || selectedOrganisation?.org_logo || logo_white
                          : selectedOrganisation?.org_logo || selectedOrganisation?.org_dark_logo || logo_white
                    }
                    alt="Logo"
                    style={{maxHeight: "30px"}}
                />
              </a>
            </div>
            <AccountMenu auth={auth} profilePhoto={profilePhoto} handleProfile={handleProfile}
                         handleLogout={handleLogout}/>
          </div>
        </Toolbar>
      </AppBar>
      <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          <div className="w-96">
            <Box display="flex" justifyContent="center" width={drawerWidth - 65}>
            <Button
              id="fade-button"
              aria-controls={orgOpenMenu ? 'fade-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={orgOpenMenu ? 'true' : undefined}
              onClick={handleOrgMenuClick}
              fullWidth
              endIcon={<ExpandMoreIcon />}
              sx={{ width: "100%" }}
            >
              <Typography noWrap={true}>{state.selectedOrganisation?.name}</Typography>
            </Button>
            </Box>
            <GradientMenu
              id="fade-menu"
              MenuListProps={{
                'aria-labelledby': 'fade-button',
              }}
              anchorEl={anchorEl}
              sx={{ width: drawerWidth + 10, marginTop: 1, marginLeft: -0.5, marginRight: 0, maxWidth: drawerWidth + 10 }}
              open={orgOpenMenu}
              onClose={handleOrgMenuClose}
              TransitionComponent={Fade}
            >
              {state.organisations.map((org: any) => (
                <GradientMenuItem key={org._id} onClick={() => onChangeOrg(org)}>
                  <Typography noWrap={true}>{org?.name}</Typography>
                </GradientMenuItem>
              ))}
            </GradientMenu>
          </div>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </DrawerHeader>
        {children}
      </Drawer>
      <Main open={open}>
        <DrawerHeader />
        {outlet}
      </Main>
    </Box>
  ); } else {
    return(
    <Box sx={{ display: 'flex', width: '100%', height: '100%' }}>
      <CssBaseline />
      {outlet}
    </Box>);
  }
};

const AccountMenu = ({ auth, profilePhoto, handleProfile, handleLogout }: any) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Tooltip title="Account settings">
        <IconButton
          onClick={handleClick}
          size="small"
          sx={{ ml: 2 }}
          aria-controls={open ? 'account-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
        >
          <Avatar sx={{ width: 32, height: 32 }}>{auth.user.email.split('')[0].toUpperCase()}</Avatar>
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&::before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem onClick={handleProfile}>
          <Avatar /> Profile
        </MenuItem>
        <Divider />
        <MenuItem>
          <ListItemIcon>
            <EmailIcon />
          </ListItemIcon>
          <a href="https://datalinkerai.atlassian.net/servicedesk/customer/portal/1" target="_blank" rel="noopener noreferrer">
            Contact Us
          </a>
        </MenuItem>
        <MenuItem onClick={handleLogout}>
          <ListItemIcon>
            <Logout />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </div>
  );
};

export default Header;
