import { useState } from "react";
import { useApp, useAuth } from "../../hooks";
import {
  createOrganisationService,
  getOrganisationService,
  updateOrganisationService,
  updateZapierTokenService,
} from "../../services/organisation.service";
import {
  TextField,
  Button,
  Grid,
  Typography,
  Box,
  FormHelperText,
  Avatar,
  Stack,
} from "@mui/material";
import { styled } from "@mui/system";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const OrganisationCreateForm = ({ action, payload, handleStatus }: any) => {
  const { dispatch, setUserData, user }: any = useApp();
  const { auth }: any = useAuth();

  const [organisation, setOrganisation] = useState({
    name: payload?.name ? payload.name : "",
    org_logo: payload?.org_logo || "",
    org_dark_logo: payload?.org_dark_logo || "",
  });

  const [uploadStates, setUploadStates] = useState({
    light: { loading: false, error: "" },
    dark: { loading: false, error: "" },
  });

  const validateFile = async (file: File) => {
  const MAX_FILE_SIZE = 2 * 1024 * 1024;
  const MAX_DIMENSION = 512;

  if (file.size > MAX_FILE_SIZE) {
    throw new Error("File size should not exceed 2MB");
  }

  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => {
      if (img.width > MAX_DIMENSION || img.height > MAX_DIMENSION) {
        reject(new Error("Image dimensions should not exceed 512x512 pixels"));
      }
      resolve(true);
    };
    img.onerror = () => reject(new Error("Invalid image file"));
    img.src = URL.createObjectURL(file);
  });
};

const handleFileChange = (type: 'light' | 'dark') => async (event: React.ChangeEvent<HTMLInputElement>) => {
  const file = event.target.files?.[0];
  if (!file) return;

  try {
    await validateFile(file);
    const reader = new FileReader();
    reader.onload = () => {
      const base64 = reader.result?.toString().split(",")[1] || "";
      setOrganisation(prev => ({
        ...prev,
        [type === 'light' ? 'org_logo' : 'org_dark_logo']: base64
      }));
    };
    reader.readAsDataURL(file);
  } catch (error: any) {
    dispatch({
      type: "SET_NOTIFICATION",
      payload: {
        show: true,
        title: "Upload Error",
        message: error.message,
        type: "error",
      },
    });
    setOrganisation(prev => ({
      ...prev,
      [type === 'light' ? 'org_logo' : 'org_dark_logo']: ""
    }));
  }
};

  const handleRemoveLogo = (type: 'light' | 'dark') => {
    setOrganisation(prev => ({
      ...prev,
      [type === 'light' ? 'org_logo' : 'org_dark_logo']: ""
    }));
  };

  const onSave = async () => {
    if (!organisation.name) {
      dispatch({
        type: "SET_NOTIFICATION",
        payload: {
          show: true,
          title: "Validation Error",
          message: "Please enter organisation name",
          type: "error",
        },
      });
      return;
    }

    if(validateOrganisationName(organisation.name)) {
        dispatch({
            type: "SET_NOTIFICATION",
            payload: {
            show: true,
            title: "Validation Error",
            message: "Organisation name must not contain only spaces or special characters",
            type: "error",
            },
        });
        return;
    }

    try {
      const payloadData = {
        ...organisation,
        ...(action === "update" && { organisation_id: payload._id }),
      };

      const response = action === "create"
          ? await createOrganisationService(payloadData)
          : await updateOrganisationService(payloadData);

      if (response) {
        if (action === "create") {
          dispatch({ type: "IS_ORGANISATION_NOTEXIST", payload: false });
          setUserData();
        } else {
          const orgRes = await getOrganisationService(payload._id);
          dispatch({ type: "SET_ACTIVE_ORGANISATION", payload: orgRes });
          handleStatus();
        }

        dispatch({
          type: "SET_NOTIFICATION",
          payload: {
            show: true,
            title: `Organisation ${action === "create" ? "Created" : "Updated"}`,
            message: `Organisation ${action === "create" ? "created" : "updated"} successfully!`,
            type: "success",
          },
        });
      }
    } catch (error) {
      dispatch({
        type: "SET_NOTIFICATION",
        payload: {
          show: true,
          title: "Operation Failed",
          message: "Something went wrong! Please try again.",
          type: "error",
        },
      });
    }
  };

  const validateOrganisationName = (name: string) => {
            const regex = /^[a-zA-Z0-9]+(?: [a-zA-Z0-9]+)*$/;
            return name.trim().length === 0 || !regex.test(name.trim());
          };

          return (
            <Box sx={{ p: 3, borderRadius: 2, boxShadow: 3, maxWidth: 800, margin: '0 auto' }}>
              <Typography variant="h5" gutterBottom sx={{ mb: 4 }}>
                {action === "create" ? "Create Organisation" : "Update Organisation"}
              </Typography>

              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Organisation Name"
                    value={organisation.name}
                    onChange={(e) => {
                      const { value } = e.target;
                        setOrganisation(prev => ({ ...prev, name: value }));
                    }}
                    variant="outlined"
                    inputProps={{ maxLength: 100 }}
                    helperText="Organisation name should not exceed 100 characters and must not contain only spaces or special characters"
                    error={organisation.name.trim().length === 0}
                  />
                </Grid>

          <Grid item xs={12} md={6}>
            <Box sx={{ border: 1, borderRadius: 2, p: 2, borderColor: 'divider', bgcolor: 'grey.100' }}>
              <Typography variant="subtitle1" gutterBottom sx={{color: "grey.800"}}>
                Light Theme Logo
              </Typography>

              <Stack direction="row" alignItems="center" spacing={2}>
                <Avatar
                    sx={{ width: 64, height: 64, bgcolor: 'grey.800' }}
                    src={organisation.org_logo
                        ? organisation.org_logo.startsWith('https:')
                            ? organisation.org_logo
                            : `data:image/png;base64,${organisation.org_logo}`
                        : ''}
                >
                  {!organisation.org_logo && <CloudUploadIcon fontSize="large" />}
                </Avatar>

                <Button
                    component="label"
                    variant="outlined"
                    startIcon={<CloudUploadIcon />}
                    sx={{ position: 'relative' }}
                >
                  Upload
                  <VisuallyHiddenInput
                      type="file"
                      onChange={handleFileChange('light')}
                      accept="image/*"
                  />
                </Button>

              </Stack>

              <FormHelperText sx={{ mt: 1 ,color: 'grey.800' }}>
                Recommended: Transparent PNG, max 512×512px (2MB)
              </FormHelperText>
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Box sx={{ border: 1, borderRadius: 2, p: 2, borderColor: 'divider', bgcolor: 'grey.800' }}>
              <Typography variant="subtitle1" gutterBottom sx={{ color: 'common.white' }}>
                Dark Theme Logo
              </Typography>

              <Stack direction="row" alignItems="center" spacing={2}>
                <Avatar
                    sx={{ width: 64, height: 64, bgcolor: 'grey.700' }}
                   src={organisation.org_dark_logo
  ? organisation.org_dark_logo.startsWith('https:')
    ? organisation.org_dark_logo
    : `data:image/png;base64,${organisation.org_dark_logo}`
  : ''}
                >
                  {!organisation.org_dark_logo && <CloudUploadIcon fontSize="large" sx={{ color: 'grey.500' }} />}
                </Avatar>

                <Button
                    component="label"
                    variant="contained"
                    startIcon={<CloudUploadIcon />}
                    sx={{ bgcolor: 'grey.700', '&:hover': { bgcolor: 'grey.600' } }}
                >
                  Upload
                  <VisuallyHiddenInput
                      type="file"
                      onChange={handleFileChange('dark')}
                      accept="image/*"
                  />
                </Button>

              </Stack>

              <FormHelperText sx={{ mt: 1, color: 'grey.400' }}>
                Recommended: White logo on transparent PNG
              </FormHelperText>
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2, mt: 4 }}>
              {action === "update" && (
                  <Button variant="outlined" onClick={handleStatus}>
                    Cancel
                  </Button>
              )}
              <Button
                  variant="contained"
                  onClick={onSave}
                  disabled={!organisation.name}
                  size="large"
              >
                {action === "create" ? "Create Organisation" : "Save Changes"}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
  );
};

export default OrganisationCreateForm;