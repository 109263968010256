import React, { useEffect, useState } from 'react';
import {
  Card,
  CardContent,
  CardHeader,
  Typography,
  Button,
  Grid,
  Container,
  Avatar,
  CircularProgress,
  Box,
  Divider,
  useTheme
} from '@mui/material';
import { Google, CheckCircle, LinkOff } from '@mui/icons-material';
import { checkGoogleAuth, disconnectGoogleAuth, connectWithGoogle } from '../services/google-tool.service';
import { useApp, useAuth } from '../hooks';

const ToolsPage = () => {
  const theme = useTheme();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);
  const { state, dispatch }: any = useApp();
  const { auth }: any = useAuth();

  useEffect(() => {
    const fetchAuthStatus = async () => {
      const authStatus = await checkGoogleAuth(state.selectedOrganisation?._id);
      setIsAuthenticated(authStatus);
      setLoading(false);
    };

    fetchAuthStatus();
  }, []);

  const handleDisconnect = async () => {
    setLoading(true);
    const disconnectStatus = await disconnectGoogleAuth(state.selectedOrganisation?._id);
    if (disconnectStatus) {
      setIsAuthenticated(false);
    }
    setLoading(false);
  };

  const handleConnect = async () => {
    setLoading(true);
    await connectWithGoogle(auth.user.uid, state.selectedOrganisation?._id);
    setLoading(false);
  };

  return (
      <Container maxWidth="md" sx={{ py: 4 }}>
        <Box mb={4}>
          <Typography
              variant="h4"
              component="h1"
              fontWeight={600}
              gutterBottom
              color="text.primary"
          >
            Integrated Tools
          </Typography>
          <Typography
              variant="body1"
              color="text.secondary"
              sx={{ mb: 2 }}
          >
            Connect your favorite productivity tools to enhance DatalinkerAI capabilities
          </Typography>
          <Divider sx={{ my: 2 }} />
        </Box>

        <Grid container justifyContent="center">
          <Grid item xs={12} md={8}>
            <Card
                sx={{
                  borderRadius: 2,
                  boxShadow: theme.shadows[3],
                  transition: 'transform 0.2s',
                  '&:hover': { transform: 'translateY(-2px)' }
                }}
            >
              <CardHeader
                  avatar={
                    <Avatar
                        sx={{
                          bgcolor: theme.palette.primary.main,
                          width: 56,
                          height: 56,
                          mr: 2
                        }}
                    >
                      <Google sx={{ fontSize: 32 }} />
                    </Avatar>
                  }
                  title={
                    <Typography variant="h6" fontWeight={600}>
                      Google Workspace
                    </Typography>
                  }
                  subheader={
                    isAuthenticated ? (
                        <Box display="flex" alignItems="center">
                          <CheckCircle sx={{ color: 'success.main', fontSize: 16, mr: 1 }} />
                          <Typography variant="body2" color="success.main">
                            Connected
                          </Typography>
                        </Box>
                    ) : (
                        <Typography variant="body2" color="text.secondary">
                          Not connected
                        </Typography>
                    )
                  }
                  sx={{ pb: 0 }}
              />

              <CardContent>
                <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{
                      lineHeight: 1.6,
                      mb: 3
                    }}
                >
                  Enable advanced integration with Google services including:
                  <ul style={{ paddingLeft: 20, margin: '8px 0' }}>
                    <li>Email management via Gmail</li>
                    <li>Calendar scheduling</li>
                    <li>Google Drive document access</li>
                    <li>Google Sheets automation</li>
                  </ul>
                </Typography>

                {loading ? (
                    <Box display="flex" justifyContent="center" py={2}>
                      <CircularProgress size={28} />
                    </Box>
                ) : isAuthenticated ? (
                    <Button
                        variant="outlined"
                        color="error"
                        fullWidth
                        startIcon={<LinkOff />}
                        onClick={handleDisconnect}
                        sx={{
                          py: 1.5,
                          textTransform: 'none',
                          fontWeight: 600,
                          borderRadius: 1
                        }}
                    >
                      Disconnect Account
                    </Button>
                ) : (
                    <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        startIcon={<Google />}
                        onClick={handleConnect}
                        sx={{
                          py: 1.5,
                          textTransform: 'none',
                          fontWeight: 600,
                          borderRadius: 1,
                          boxShadow: theme.shadows[2],
                          '&:hover': { boxShadow: theme.shadows[4] }
                        }}
                    >
                      Connect Google Account
                    </Button>
                )}
              </CardContent>
            </Card>
          </Grid>
        </Grid>

      </Container>
  );
};

export default ToolsPage;