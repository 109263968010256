import { Link, useParams } from 'react-router-dom';
const PaymentStatusPage = () => {
  const { status, orgId } = useParams();
  return (
    <div className='flex justify-center items-center' style={{ height: "100dvh" }}>
      <div className='shadow-lg p-8'>
        <div className='mb-8'>
          <img src="/images/thevirtulab.png" width={150} alt="The Virtulab" />
          <h1 className='mt-4 text-xl font-bold'>The Virtulab Payment Portal</h1>
        </div>
        <div className='flex justify-center text-center'>
          {
            status === 'success' ? (
              <h1 className="text-green-800">Payment initiated successfully</h1>
            ) : (
              <h1 className="text-red-600">Payment calcelled successfully</h1>
            )
          }
        </div>

        <div className='flex justify-end mt-8'>
          <Link to={`/organisation/${orgId}/settings`} className="border py-2 px-4 rounded bg-gray-800 text-white"> Back </Link>
        </div>
      </div>
    </div>
  )
}

export default PaymentStatusPage